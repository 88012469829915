export default ({ $http, api, qs }) => {
  // Exports
  return {
    /**
     * OSS Upload
     * ========== ========== ==========
     */
    async OSS_UPLOAD({}, params = {}) {
      return await $http(api.ossUpload).post({ ...qs, ...params });
    },

    /**
     * OSS Update
     * ========== ========== ==========
     */
    async OSS_DOWNLOAD({}, params = {}) {
      return await $http(api.ossDownload).post({ ...qs, ...params });
    },

    /**
     * OSS File List Get
     * ========== ========== ==========
     */
    async OSS_LIST({ state }, { ossType, trigger = false }) {
      // Hard Trigger
      if (trigger) {
        return await $http(api.ossList).post({ ...qs, ossType });
      }

      // No State Cache
      return state.preset.ossFileList.length ? state.preset.ossFileList : await $http(api.ossList).post({ ...qs, ossType });
    },

    /**
     * OSS File Delete
     * ========== ========== ==========
     */
    async OSS_DELETE({ state }, { id }) {
      return await $http(api.ossDelete).post({ ...qs, id });
    },

    /**
     * OSS File Copy
     * ========== ========== ==========
     */
    async OSS_COPY({ state }, id) {
      return await $http(api.ossCopy).post({ id });
    },
  };
};
