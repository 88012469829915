<style lang="less" scoped>
.page {
  @vertexLine: rgba(236, 236, 236, 0.75);

  background-color: white;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);

  position: relative;

  & {
    // margin: 0 auto 10vh;
    margin-bottom: 10vh;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &,
  &--header,
  &--footer,
  &--main {
    transition: all 0.24s;

    // For Others Absolute
    position: relative;

    :deep(& > div) {
      height: 100%;
    }

    // Focus Mode
    &.focus {
      &::after {
        content: '';
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 4px solid rgba(#1890ff, 0.5);
      }
    }
  }

  &--header,
  &--footer {
    position: relative;

    &:after {
      z-index: 1;
      left: 6%;
      position: absolute;
      display: block;

      width: 4rem;
      line-height: 2rem;

      text-align: center;

      color: darken(@vertexLine, 36%);
      background-color: @vertexLine;
    }
  }

  &--header {
    border-bottom: 1px solid #eee;

    &:after {
      content: '页眉';
      border-radius: 0 0 4px 4px;
      top: 100%;
    }
  }

  &--footer {
    border-top: 1px solid #eee;

    &:after {
      content: '页脚';
      border-radius: 4px 4px 0 0;
      bottom: 100%;
    }
  }

  &--number {
    position: absolute;
  }

  &--auxiliary {
    top: 0;
    bottom: 0;
    left: 50%;
    position: absolute;
    border-left: 1px dashed cyan;
  }
}
</style>

<template>
  <div class="page" :style="style">
    <div :class="{ 'page--auxiliary': setting.gridAuxiliary }" />

    <div class="page--header" v-if="vertexVisible.header" :style="visualUtils.px(compass.header)">
      <slot name="header" :size="compass.header" :unit="unit" />
    </div>

    <div class="page--main" :style="visualUtils.px(compass.main)">
      <visual-topnav v-if="mobox"></visual-topnav>
      <slot :size="compass.main" :unit="unit" />
    </div>

    <div class="page--footer" v-if="vertexVisible.footer" :style="visualUtils.px(compass.footer)">
      <slot name="footer" :size="compass.footer" :unit="unit" />
    </div>

    <div class="page--number" v-if="false && visual.setting.pageNumber" :style="pageNumberPadding">{{ index + 1 }} / {{ visual.store.length }}</div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: [Number],
      default() {
        return -1;
      },
    },

    hardness: {
      type: [Object],
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      // Torance
      rance: 1,
    };
  },

  methods: {
    line(x = 90, y = 180) {
      // Set Line Colour
      const colour = `rgba(0, 0, 0, 0.1)`;

      // Set Line Weight
      const line = `1px`;

      // Set Gradient
      const gradient = {
        x: `linear-gradient(${x}deg, ${colour} ${line}, transparent, ${line}) 0% 0% / ${this.one.x}px ${this.one.y}px`,
        y: `linear-gradient(${y}deg, ${colour} ${line}, transparent ${line}) 0% 0% / ${this.one.x}px ${this.one.y}px`,
      };

      // Set Background Image
      const backgroundImage = this.setting.gridVisible ? `linear-gradient(${x}deg, ${colour} ${line}, transparent ${line}), linear-gradient(${y}deg, ${colour} ${line}, transparent ${line})` : `none`;

      // Set Background Size
      const backgroundSize = `${this.one.x}px ${this.one.y}px, ${this.one.x}px ${this.one.y}px`;

      // Return
      return {
        backgroundImage,
        backgroundSize,
      };
    },
  },

  computed: {
    topStyle() {
      let style = {
        //fontSize: this.setting.titleStyle.size + 'px',
        //color: this.setting.titleStyle.color,
      };
      return style;
    },
    auto() {
      return this.fluid || this.mobox;
    },
    scene() {
      // Get Custom
      const custom = this.setting.mode === 'custom';

      // Custom First
      if (custom) {
        return this.setting.size;
      }

      // Else Space
      const space = this.visual.preset.page[this.setting.mode];

      // Set Size
      const size = {
        w: this.setting.orientation ? space[1] : space[0],
        h: this.setting.orientation ? space[0] : space[1],
      };

      // Update to Store
      this.$store.update('visual', {
        setting: {
          size,
        },
      });

      // Return
      return size;
    },

    // No Use
    sizes() {
      // Return for Distance
      return {
        // Set Width
        w: this.visualUtils.mm2px(this.scene.w),
        // Set Height
        h: this.visualUtils.mm2px(this.scene.h),
      };
    },

    // One Grid
    one() {
      // Mini Set First
      if (!this.setting.gridMinify) {
        return this.setting.mini;
      }

      // Return
      return this.block;
    },

    // Block for One Grid
    block() {
      return {
        // Set X
        x: this.visualUtils.suffix((this.sizes.w - this.crevice.position.horizontal) / this.setting.grid.x),
        // Set Y
        y: this.visualUtils.suffix((this.sizes.h - this.crevice.position.vertical) / this.setting.grid.y),
      };
    },

    // Unit for Calculate, like Page or Any
    unit() {
      return this.setting.gridMinify ? this.one : this.block;
    },

    // Padding
    crevice() {
      // Return
      return {
        // Css for Use
        css: {
          // Top
          paddingTop: this.mmPadding.top,
          // Left
          paddingLeft: this.mmPadding.left,
          // Right
          paddingRight: this.mmPadding.right,
          // Bottom
          paddingBottom: this.mmPadding.bottom,
        },

        // Position for Use
        position: {
          // Horizontal
          horizontal: this.mmPadding.left + this.mmPadding.right,
          // Vertical
          vertical: this.mmPadding.top + this.mmPadding.bottom,
        },
      };
    },

    // Hardress
    hardress() {
      return {
        horizontal: this.hardness.width + this.crevice.position.horizontal,
        vertical: this.hardness.height + this.crevice.position.vertical,
      };
    },

    // Page Style
    style() {
      // Set Width
      const width = this.visualUtils.px(this.auto ? this.hardress.horizontal : this.sizes.w);

      // Set Height
      const height = this.auto ? 'auto' : this.visualUtils.px(this.sizes.h);

      // Set Min Height for Fluid
      const minHeight = this.auto ? this.visualUtils.px(this.hardness.height ? this.hardress.vertical : this.sizes.h) : 'auto';

      // Set Background Color
      const color = `255, 255, 255`; // this.background.color;

      // Get Properties from BackgroundCSS
      const { x, y, size, opacity, url } = this.background;

      // Set Alpha
      const alpha = `rgba(${color}, ${1 - opacity})`;

      // Set Background Image
      const backgroundColor = `rgb(${color})`;
      const backgroundImage = this.preview ? `none` : `linear-gradient(${alpha}, ${alpha}), url('${url}')`;
      // const backgroundImage = `linear-gradient(${alpha}, ${alpha}), url('${url}')`;
      const backgroundPosition = `${x}px ${y}px`;
      const backgroundSize = size;

      // Return
      return {
        width,
        height,
        minHeight,
        backgroundSize,
        backgroundRepeat: 'no-repeat',
        backgroundColor,
        backgroundImage,
        backgroundPosition,
        ...this.visualUtils.px(this.crevice.css),
      };
    },

    // Visible of Vertex
    vertexVisible() {
      // Get First
      const first = this.index === 0;

      // Get Last
      const last = this.index === this.visual.store.length - 1;

      // Set Header
      const header = this.vertexHeader.every === true ? this.vertexHeader.visible : first && this.vertexHeader.visible;

      // Set Footer
      const footer = this.vertexFooter.every === true ? this.vertexFooter.visible : last && this.vertexFooter.visible;

      // Export
      return { header, footer };
    },

    // Space of Vertex
    vertexSpace() {
      // Set Header
      const header = this.vertexHeader.space * this.unit.y + this.rance;

      // Set Footer
      const footer = this.vertexFooter.space * this.unit.y + this.rance;

      // Set Main
      const main = this.vertexMain.space * this.unit.y + this.rance;

      // Export
      return { header, footer, main };
    },

    // Wild of Vertex
    vertexWidth() {
      // Set Width All
      return this.unit.x * this.setting.grid.x + this.rance;
    },

    // Compass Of
    compass() {
      // Update One
      this.$store.update('visual', {
        setting: {
          one: this.one,
        },
      });

      // Set Header
      const header = {
        width: this.vertexWidth,
        height: this.vertexSpace.header,
      };

      // Set Footer
      const footer = {
        width: this.vertexWidth,
        height: this.vertexSpace.footer,
      };

      // Set Main
      const main = {
        width: this.auto ? this.hardness.width : this.vertexWidth,
        height: this.auto ? `auto` : this.vertexSpace.main,
        minHeight: this.auto ? this.hardness.height || this.vertexSpace.main : 'auto',
      };

      // Return
      return {
        header,
        footer,
        main,
      };
    },

    // Page Pixel to MM
    mmPadding() {
      // Mobox No Padding
      return this.mobox
        ? {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }
        : {
            top: this.visualUtils.mm2px(this.setting.padding.top),
            left: this.visualUtils.mm2px(this.setting.padding.left),
            right: this.visualUtils.mm2px(this.setting.padding.right),
            bottom: this.visualUtils.mm2px(this.setting.padding.bottom),
          };
    },

    // Page Number Padding
    pageNumberPadding() {
      // Get Position from Padding
      const { right, bottom } = this.visualUtils.px(this.mmPadding);

      // Return
      return { right, bottom };
    },
  },

  watch: {
    'setting.size.w'(value) {
      const space = this.visual.preset.page[this.setting.mode][this.setting.orientation ? 1 : 0];

      if (space === value) {
        return;
      }

      this.$store.update('visual', {
        setting: {
          mode: 'custom',
        },
      });
    },

    'setting.size.h'(value) {
      const space = this.visual.preset.page[this.setting.mode][this.setting.orientation ? 0 : 1];

      if (space === value) {
        return;
      }

      this.$store.update('visual', {
        setting: {
          mode: 'custom',
        },
      });
    },
  },
};
</script>
