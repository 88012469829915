export default ({ modern, fluid, mobox, grid, query }) => {
  const mode = {
    grid: 'A4',
    mobox: 'iphoneSE',
    fluid: 'A4',
  }[modern];

  return {
    // Setting for Page
    setting: {
      // Get Materials from OSS
      material: 'printer',

      // Title Name
      title: '未命名',

      // Title Style
      titleStyle: {
        color: '#2a2a2a',
        size: '18px',
      },

      // Background Colour
      backgroundCss: {
        color: '#fff',
        opacity: 1,
        x: 20,
        y: 20,
        size: 'contain',
        url: '',
      },

      // Background Image
      backgroundActive: null,

      // Page Mode
      mode,

      // Page Orientation
      orientation: false,

      // Page Size (Pixel) - Default A4
      size: {
        w: 0,
        h: 0,
        scale: 1,
      },

      // Page Grid: 0 - 10
      grid: {
        x: 50,
        y: 70,
      },

      // Page Flow Option
      flow: {
        // 槽位间距
        gap: 12,
        // 缺省行高
        line: fluid ? 40 : 500,
      },

      // Page Grid Mini: 1
      mini: {
        x: 1,
        y: 1,
      },

      // Page Grid Visible
      gridVisible: true,

      // Page Grid Guide
      gridAuxiliary: false, // true

      // Page Grid Minify
      gridMinify: true,

      // One of Grid (Pixel)
      one: {
        x: 0,
        y: 0,
      },

      // Padding of Page
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },

      // Page Number
      pageNumber: false,

      // 眉脚
      vertex: {
        /**
         * Mode
         * @prop { every }
         * @prop { end }
         * ====== ====== ======
         */

        main: {
          space: 25,
        },

        // Header of Page
        header: {
          visible: false,
          space: 3,
          every: false,
        },

        // Footer of Page
        footer: {
          visible: false,
          space: 2,
          every: false,
        },
      },

      // 眉脚状态
      vertexStatus: {
        header: 0,
        footer: 0,
      },

      // Repeat Info
      repeat: {
        name: false,
        index: 0,
        limit: 10,
      },
    },
  };
};
