// Use Path Read
import pathRead from 'path-read';

// Use SDK Requirer
import requirer from '@skylla/sdk/requirer';

// Use SDK Component
import { iVueFrame, VueDraggable, VueDraggableDirective } from '@skylla/sdk/vue';

// Use Simulator
import simulator from '@skylla/emulator';

// Use Store
import Store from './src/__store__';

// Use Mixin
import Mixin from './src/__mixin__';

// Use Utils
import * as visualUtils from './src/__utils__';

// Use Apis
import visualApis from './src/__api__';

// Use Utils
import { materialsLoad, propExtension, packageUse, register, initFontAwesomeIcons } from './src/__utils__/context';

// Use Components Entry
import visual from './src/index.vue';

// Use Style
import './src/style.less';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Use Components Any
const Components = require.context('./src', true, /\.vue$/);

// Needs Use ?
const { qs, Preview, capture } = visualUtils;

// Nipper
const ripper = async function({ app, options }) {
  // If is Installed
  if (install.installed) {
    return;
  }

  // is Install
  install.installed = true;

  // First First First, Get Injection Info
  try {
    window.$injection = await window.injection();
  } catch (e) {}

  // Register Components Entry
  app.component('visual', visual);

  // Init Font Awesome
  initFontAwesomeIcons();

  // Register Components Font-Awesome
  app.component('font-awesome-icon', FontAwesomeIcon);

  // Register Components
  requirer(Components, app);

  // Register Directive for Draggable
  app.directive('dragAndDrop', VueDraggableDirective);

  // Extension Mixin
  app.mixin(Mixin);

  // Extension Properties
  propExtension(app, {
    visualUtils,
    visualApis,
    qs,

    captureInterceptor: app.captureInterceptor,

    $materials: {},
    $optionals: {},

    preview: /preview$/.test(location.pathname),
  });

  // Package Use
  packageUse(app, [iVueFrame, simulator, VueDraggable]);

  /**
   * Load Materials
   * ========== ========== ==========
   * 如：有 id 的情况，则待读取 store 数据后，加载 materials（action: visual/STORE_GET）
   * 如：没有 id，则根据 version 参数，加载 materials
   * 如：无 version 参数，则加载 latest 版本
   */
  qs().id ? options.next() : materialsLoad({}, options.next);

  // Focus for Frame Load
  window.focus();
};

// Set Install for `app.use`
const install = (app, options = {}) =>
  // Use Capture as Promise
  capture(
    // Resolve
    ripper,

    // Reject
    app.captureInterceptor,

    // Process
    'engine',

    // Crossover
    { app, options },
  );

/**
 * Export as API
 * ========== ========== ==========
 */

// Export as Plugin
export const visualPlugin = { install };

// Export as Store
export const visualStore = Store;

// Export as Gui Hook
export const visualGui = Gui => register(Gui, window.App);

// Export API Print
export const print = Preview;

// Export Component
export default visual;
