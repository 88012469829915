<style lang="less" scoped></style>

<template>
  <a-form-item :label="`${title}`">
    <slot />
  </a-form-item>
</template>

<script>
export default {
  props: {
    title: {
      type: [String],
      default() {
        return "";
      },
    },
  },
};
</script>
