export default {
  props: {
    source: {},
    option: {},
    vars: {},
    axes: {},
  },

  computed: {
    /**
     * Visual Store
     * ========== ========== ==========
     */
    query() {
      return this.qs();
    },

    /**
     * Visual Store
     * ========== ========== ==========
     */
    keeper() {
      return this.visual.store;
    },

    /**
     * Visual First in Keeper
     * ========== ========== ==========
     */
    first() {
      return this.keeper[0];
    },

    /**
     * Visual Resource
     * ========== ========== ==========
     */
    resource() {
      return this.visual.resource;
    },

    /**
     * Visual Content
     * ========== ========== ==========
     */
    // content() {
    //   return this.visual.content;
    // },

    /**
     * Vertexes
     * ========== ========== ==========
     */
    vertexes() {
      return this.visual.vertexes;
    },

    /**
     * Preset About
     * ========== ========== ==========
     */
    preset() {
      return this.visual.preset;
    },

    // formatter() {
    //   return this.preset.formatter;
    // },

    presetVertex() {
      return this.preset.vertex;
    },

    presetVertexHeader() {
      return this.presetVertex.header;
    },

    presetVertexFooter() {
      return this.presetVertex.footer;
    },

    /**
     * Can
     * ========== ========== ==========
     */
    can() {
      return this.visual.can;
    },

    /**
     * History About
     * ========== ========== ==========
     */
    history() {
      return this.visual.history;
    },

    /**
     * Material About
     * ========== ========== ==========
     */
    materials() {
      return this.visual.materials;
    },

    /**
     * Setting About
     * ========== ========== ==========
     */
    setting() {
      return this.visual.setting;
    },

    modern() {
      return this.visual.modern;
    },

    mobox() {
      return this.visual.mobox;
    },

    grid() {
      return this.visual.grid;
    },

    // mini() {
    //   return this.setting.mini;
    // },

    background() {
      return this.setting.backgroundCss;
    },

    one() {
      return this.setting.one;
    },

    repeat() {
      return this.setting.repeat;
    },

    // padding() {
    //   return this.setting.padding;
    // },

    /**
     * Vertex About
     * ========== ========== ==========
     */
    vertex() {
      return this.setting.vertex;
    },

    vertexStatus() {
      return this.setting.vertexStatus;
    },

    vertexMain() {
      return this.vertex.main;
    },

    vertexHeader() {
      return this.vertex.header;
    },

    vertexFooter() {
      return this.vertex.footer;
    },

    /**
     * Interface
     * ========== ========== ==========
     */
    interface() {
      return this.visual.interface;
    },

    stage() {
      return this.interface.stage;
    },

    adsorbent() {
      return this.stage.adsorbent;
    },

    distance() {
      return this.stage.distance;
    },

    console() {
      return this.interface.console;
    },

    /**
     * Visual Poster
     * ========== ========== ==========
     */
    poster() {
      return this.visual.poster;
    },

    /**
     * Visual Quick Modern
     * ========== ========== ==========
     */
    modern() {
      return this.visual.modern;
    },

    mobox() {
      return this.visual.mobox;
    },
    fluid() {
      return this.visual.fluid;
    },
    grid() {
      return this.visual.grid;
    },

    /**
     * Visual Original Materials
     * ========== ========== ==========
     */
    original() {
      return this.visual.original;
    },

    /**
     * Visual Original Materials Sync
     * ========== ========== ==========
     */
    originalSync() {
      return this.visual.originalSync;
    },

    /**
     * Visual Original Materials Sync
     * ========== ========== ==========
     */
    pfs() {
      return this.visual.pfs;
    },
  },

  errorCaptured(error, instance, process) {
    // Use captureInterceptor Global
    this.captureInterceptor({ process, error, instance });

    // Stop Propagation
    return false;
  },
};
