<style lang="less" scoped>
.flex {
  display: flex;
}
</style>

<template>
  <div class="flex" :style="css">
    <slot />

    <div class="flex--affix">
      <slot name="affix" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: [String],
      default() {
        return 'center';
      },
    },
    content: {
      type: [String],
      default() {
        return 'space-between';
      },
    },
    direction: {
      type: [String],
      default() {
        return 'row';
      },
    },
  },

  computed: {
    css() {
      return {
        'align-items': this.align,
        'justify-content': this.content,
        'flex-direction': this.direction,
      };
    },
  },
};
</script>
