<style lang="less" scoped></style>

<template>
  <div class="optional" v-if="visual.active">
    <component v-if="live.name" :is="`${live.name}-optional`" :source="live.source" :option="live.option" :axes="live.axes" />
  </div>
</template>

<script>
export default {
  methods: {
    check(id, namespace) {
      return this.context[namespace].filter(item => item.id === id);
    },

    snake(source = [], target = null) {
      this.visualUtils.deep(source, (value, key, subject, path) => {
        if (key === 'id' && value === this.visual.active.id) {
          target = subject;
        }
      });

      return target;
    },

    // Mobox 模式下拿取配置项
    snakeMobox(source, target = null) {
      for (let i = 0; i < source.context.length; i++) {
        if (source.context[i].id === this.visual.active.id) {
          target = source.context[i];
        }
      }

      return target;
    },
  },

  computed: {
    // Live Component
    live() {
      // Preset Component
      let component = {};

      // Deep Check Keeper
      if (this.visualUtils.empty(component)) {
        this.mobox ? component = this.snakeMobox(this.first) : component = this.snake(this.keeper);
      }

      // Deep Check Header of Vertexes
      if (this.visualUtils.empty(component)) {
        component = this.snake(this.vertexes.header);
      }

      // Deep Check Footer of Vertexes
      if (this.visualUtils.empty(component)) {
        component = this.snake(this.vertexes.footer);
      }
      // console.log(component);
      // Live Component
      return component || {};
    },
  },
};
</script>
