export default ({ qs }) => {
  // Exports
  return {
    /**
     * Environment of Browser
     * ========== ========== ==========
     */
    async ENV() {
      const preview = /\/preview/.test(location.pathname);

      return {
        qs,
        preview,
      };
    },
  };
};
