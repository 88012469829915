<style lang="less" scoped>
._ {
  background-color: white;

  opacity: 1;
  transition: opacity 360ms;

  &.hidden {
    opacity: 0;
  }
}
</style>

<template>
  <div class="_">
    <visual />
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      hidden: true,
    };
  },
  async mounted() {
    this.$nextTick(() => {
      this.hidden = false;
    });
  },
};
</script>
