<style lang="less" scoped>
.floor {
  @indent: 8px;
  @effect: 0s; // 0.12s;
  @color: #fff;
  @theme: #00a6c9;

  overflow: hidden;
  background-color: transparent;

  cursor: grab;

  .effect(@alpha: 0.825) {
    color: white !important;
    background-color: rgba(@theme, @alpha) !important;

    .floor--icon {
      opacity: 1;
    }

    :deep(.ant-btn) {
      color: @theme !important;
      border-color: white !important;
      background-color: white !important;
    }
  }

  &--container {
    &.active {
      // cursor: grabbing !important;
      .effect(1);
    }

    &:hover {
      background: #e6fbff;

      .floor--icon {
        opacity: 1;
        // color: @theme;
      }
    }

    &.sortable-chosen {
      .effect(1); // 0.375

      cursor: grabbing !important;
    }

    .divider {
      border-top: 1px solid #d9d9d9;
    }
  }

  &--item {
    width: 90%;
    line-height: 40px;
    padding: 0 @indent;
    display: flex;
    align-items: center;
  }

  &--icon {
    transition: opacity @effect, color @effect;

    width: 10%;
    float: right;
    line-height: 40px;

    opacity: 0;

    &:hover {
      // color: darken(@theme, 0.5);
    }
  }

  &--anchor {
    justify-content: center;

    width: 24px;
    padding: 0;
  }

  &--content {
    span {
      vertical-align: top;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>

<style lang="less">
.floor--popover {
  button,
  i {
    display: block;
  }

  button {
    width: 100%;
    text-align: left;
  }

  i {
    margin: 5px 0 6px;
    border-top: 1px solid #f9f9f9;
  }

  .ant-popover-inner-content {
    padding: 4px;
  }
}
</style>

<template>
  <dragger class="floor" :group="{ name: 'floor', pull: 'clone', put: true }" :sort="true" animation="360" v-model="(keeper[0] || {}).context" item-key="item" @mouseleave="originSyncOut">
    <template #item="{ element, index }">
      <div class="floor--container" :class="{ active: original === index }" @click="originSet(index)" @mouseenter="originSyncSet(index)">
        <a-popover placement="right" trigger="hover" overlayClassName="floor--popover">
          <template #content>
            <a-button type="text" @click="onFloorDelete(element, index)">删除楼层</a-button>
            <i />
            <a-button type="text" @click="onFloorAdd(element, index, false)">上方新增一个楼层</a-button>
            <a-button type="text" @click="onFloorAdd(element, index, true)">下方新增一个楼层</a-button>
          </template>
          <MoreOutlined class="floor--icon" />
        </a-popover>

        <a-space class="floor--item">
          <a-button type="primary" size="small" class="floor--anchor">{{ index }}</a-button>

          <div class="floor--content" v-for="(item, key) in element">
            <span v-if="key < 6">
              <visual-gui-icon :mode="item.meta.iconfont" />
            </span>
          </div>
        </a-space>
      </div>
    </template>
  </dragger>
</template>

<script>
export default {
  data() {
    return {
      drag: false,
      active: -1,
    };
  },

  methods: {
    onFloorDelete(element, index) {
      const that = this;

      that.$confirm({
        title: '是否确认删除该楼层？',

        onOk() {
          that.keeper[0].context.splice(index, 1);
        },
      });
    },

    onFloorAdd(element, index, position) {
      // Number
      position = position - 0;

      // Add Empty Floor
      this.keeper[0].context.splice(index - position, 0, []);

      // Calculate Active
      if (~this.original) {
        this.$store.update('visual', {
          original: this.original + (1 - position),
        });
      }
    },

    originSet(original) {
      this.$store.update('visual', {
        original,
      });
    },

    originSyncSet(originalSync) {
      this.$store.update('visual', {
        originalSync,
      });
    },

    originSyncOut() {
      this.$store.update('visual', {
        originalSync: -1,
      });
    },
  },
};
</script>
