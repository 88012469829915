<style lang="less" scoped>
.toper {
  height: 100%;
  padding: 0 16px;

  h1 {
    min-width: 3rem;
    max-width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 14px;
  }

  [class^='input-number'] {
    position: relative;

    &:after {
      top: 0;
      right: 0;
      position: absolute;

      color: rgba(0, 0, 0, 0.25);

      line-height: 32px;
      padding-right: 4px;
    }
  }

  .input-number-auto-wrap {
    margin-bottom: 0;
  }

  .input-number-auto {
    width: 96px;

    &:after {
      content: '分钟自动';
    }
  }

  &--step {
    padding-left: 1rem;

    .redo,
    .undo {
      width: 32px;
      padding: 0;
      border: none;

      &:focus {
        color: inherit;
      }

      &:hover {
        color: inherit;
        background-color: #f3f4f5;
      }

      &.disabled {
        color: #a3a4a5;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .downloadtag {
    width: 0;
    overflow: hidden;
    height: 0;
    display: inline-block;
  }
}
</style>

<template>
  <visual-gui-flex class="toper">
    <a-space>
      <a-tooltip :title="visual.setting.title">
        <h1>{{ visual.setting.title }}</h1>
      </a-tooltip>

      <form-outlined @click="edit" />

      <!-- 前进回退的图标隐藏 -->
      <!-- <a-space class="toper--step">
        <a-button class="redo" :class="{ disabled: step.redo }" @click="redo">
          <visual-gui-icon mode="shangyibu" />
        </a-button>
        <a-button class="undo" :class="{ disabled: step.undo }" @click="undo">
          <visual-gui-icon mode="xiayibu" />
        </a-button>
      </a-space> -->

      <a-modal v-model:visible="visible" title="标题修改">
        <a-input v-model:value="title" class="stop" placeholder="请输入" />

        <template #footer>
          <a-button type="primary" @click="success">确定</a-button>
        </template>
      </a-modal>
    </a-space>

    <template #affix>
      <a-space :size="16">
        <a-button @click="() => $store.update('visual', { active: null })"> 页面设置</a-button>

        <a-space v-if="!mobox">
          <a-upload accept=".json" v-model:file-list="importFileList" :beforeUpload="beforeUpload" :customRequest="customRequest" :show-upload-list="false">
            <a-button>
              <span>文件导入</span>
              <PaperClipOutlined v-if="false" />
            </a-button>
          </a-upload>
          <a-button @click="exportFile">
            <span>文件导出</span>
            <CloudDownloadOutlined v-if="false" />
          </a-button>
          <a class="downloadtag" ref="exportFileLink" :href="fileLink" target="_blank" download>123</a>
        </a-space>

        <a-space v-if="query.draft">
          <a-dropdown>
            <template #overlay>
              <a-menu @click="draft">
                <a-menu-item key="save">
                  <EditOutlined />
                  <span>保存</span>
                </a-menu-item>
                <a-menu-item key="load">
                  <ReadOutlined />
                  <span>读取</span>
                </a-menu-item>
              </a-menu>
            </template>
            <a-button>
              <span>本地草稿</span>
              <ContainerOutlined />
            </a-button>
          </a-dropdown>
        </a-space>

        <!-- 预览 -->
        <a-space>
          <a-tooltip>
            <template #title>
              <a-space>
                <div>
                  <img width="180" :src="qr" />
                </div>
              </a-space>
            </template>

            <a-button type="primary">
              <span>预 览</span>
              <LoadingOutlined v-if="visual.printer" />
            </a-button>
          </a-tooltip>

          <!-- 发布 -->
          <a-button type="primary" @click="publish">保存</a-button>

          <a v-if="false" :href="preview" target="_blank" class="ant-btn ant-btn-primary" @click.native="intercept">预览</a>
        </a-space>
      </a-space>
    </template>
  </visual-gui-flex>
</template>

<script>
import { bindMessage, sendMessage, print } from '@skylla/sdk/api';

export default {
  data() {
    // Preset That
    const that = this;

    return {
      qr: null,

      preview: './preview',

      visible: false,

      timeout: null,

      title: '',
      importFileList: [],
      exportFileList: [],
      fileLink: void 0,
    };
  },

  computed: {
    step() {
      return {
        redo: !(this.visual.step > 0),
        undo: !(this.visual.step < this.history.cache.length - 1),
      };
    },
  },

  methods: {
    async redo() {
      if (this.step.redo) {
        return;
      }

      await this.$store.dispatch('visual/HISTORY_REDO');
      await this.$store.dispatch('visual/HISTORY_LOAD');
    },

    async undo() {
      if (this.step.undo) {
        return;
      }

      await this.$store.dispatch('visual/HISTORY_UNDO');
      await this.$store.dispatch('visual/HISTORY_LOAD');
    },

    // Json 2 Query
    json2query(json = {}, result = '') {
      Object.keys(json).forEach(key => {
        result += `&${key}=${json[key]}`;
      });

      return result + '&time=' + new Date().getTime();
    },

    // 废弃
    intercept(e) {
      // Check ID
      if (!~this.visual.id) {
        this.$message.error('请先保存文稿');
        e.preventDefault();
        return false;
      }

      // Set Preview Link
      this.preview = `./preview?id=${this.visual.id}`;

      // Preview
      return true;
    },

    // 预览（打印）
    printercept(e) {
      // Check ID
      // if (!~this.visual.id) {
      //   this.$message.error('请先保存文稿');
      //   e.preventDefault();
      //   return false;
      // }

      // // As Poster
      // if (this.poster) {
      //   // 向外部索取数据，在 <editor.vue> 中等待 <onPrintWorkCross>
      //   return sendMessage({ mode: 'onPrintReadyCross' });
      // }

      // Stop to Use
      return;

      // Get Params for Print
      const { resource, content, fluid, store, vertexes, setting } = this.visual;

      // Set Local in Editor Env
      const local = { fluid, store, vertexes, setting };

      // Single - Resource and Content in Visual
      print({ host: location.origin, query: { id: this.visual.id, ...this.query } }, { resource, content, local });

      // Stop Link
      return false;
    },

    edit() {
      this.title = this.visual.setting.title;
      this.visible = true;
    },

    success(value) {
      this.$store.update('visual', {
        setting: {
          title: this.title,
        },
      });
      this.visible = false;
    },

    // 发布（保存）
    async publish() {
      // Update
      const { success } = await this.$store.dispatch('visual/PFS_UPDATE');

      // Success
      return success ? this.$message.success('保存成功') : this.$message.error('保存失败');

      /* Stop Origin Version with Linked */

      // Get ID
      const { id, lock } = await this.$store.dispatch('visual/STORE_UPDATE');

      // No ID on Query
      if (!this.$route.query.id) {
        // Redirect
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.query,
            id,
          },
        });
      }

      if (lock) {
        return this.$message.warning('请勿重复保存');
      }

      if (id) {
        return this.$message.success('保存成功');
      }

      return this.$message.error('保存失败');
    },

    // 保存草稿
    async save() {
      // 走真实保存
      const { id, lock } = await this.$store.dispatch('visual/STORE_UPDATE');

      // 保存成功
      if (id) {
        return this.$message.success('文档保存成功');
      }

      // 草稿改成真实保存
      return;

      const success = await this.$store.dispatch('visual/STORE_SAVE');

      if (success) {
        this.$message.success('草稿保存成功');
      }
    },

    // 读取草稿
    async load() {
      const success = await this.$store.dispatch('visual/STORE_LOAD');

      if (success) {
        this.$message.success('草稿读取完成');
      }
    },

    // 草稿
    async draft({ key }) {
      await this[key]();
    },

    // 文件导入 -- 上传前读区文件信息
    beforeUpload(file) {
      // That
      const that = this;

      // Craete a Reader of File
      const reader = new FileReader();

      // Read as Text
      reader.readAsText(file);

      // Do Something when Onload
      reader.onload = async evt => {
        // Read Stream of File
        const stream = evt?.target?.result || '';

        // No Stream as Failed
        if (!stream) {
          return this.$message.error('导入失败');
        }

        // Lock History First
        await this.$store.update('visual', {
          history: {
            lock: true,
          },
        });

        // Preset Cache for ID and Title
        const preset = {
          id: this.visual.id,
          title: this.setting.title,
        };

        // Trigger Download Action
        const anyway = await this.$store.dispatch('visual/STORE_PARSE', stream);

        // 因为要对 ID 和 Title 做特殊处理，所以此处只能 Hard Code
        anyway.id = preset.id;

        // Set Title
        anyway.setting = anyway.setting || {};
        anyway.setting.title = preset.title;

        // 再硬核更新数据
        this.$store.update('visual', {
          ...anyway,
        });

        // Clean Cache
        this.clearlist();

        // Wait for 1000ms
        await this.visualUtils.wait(1000);

        // Clean History Cache
        await this.$store.dispatch('visual/HISTORY_CLEAN');

        // Exports
        return false;
      };
    },

    // 阻止默认上传行为
    customRequest(e) {
      return false;
    },

    // 导入
    // async importFile(info) {
    //   console.log('importFile', info);
    //   if (info.file.status !== 'uploading') {
    //     console.log(info.file, info.fileList);
    //   }

    //   if (info.file.status === 'done') {
    //     // 新建一个FileReader
    //     const reader = new FileReader();
    //     // 获取原生数据
    //     const target = info.fileList[0].originFileObj;
    //     // 读取文件
    //     reader.readAsText(target, 'UTF-8');
    //     // 读取完文件之后获取内容
    //     reader.onload = async (evt) => {
    //       // 读取文件内容
    //       const fileString = evt.target.result;
    //       await this.$store.dispatch('visual/STORE_DOWNLOAD', fileString);
    //       this.importFileList = [];
    //     };
    //     this.$message.success(`${info.file.name} 文件上传成功`);
    //   } else if (info.file.status === 'error') {
    //     this.$message.error(`${info.file.name} 文件上传失败`);
    //   }
    // },

    // Clean Cache as List of Import
    clearlist(arr) {
      this.importFileList.splice(0, 1);
    },

    // 导出
    async exportFile(event) {
      const id = this.visual.id;

      if (!~this.visual.id) {
        return this.$message.error(`请先保存.`);
      }

      /* 文件存在性校验迁移至后端处理
      const storeCheck = await this.$store.dispatch('visual/STORE_CHECK', {
        id,
      });

      if (!storeCheck) {
        return this.$message.error(`导出失败.`);
      }
      */

      const { dataOssUrl } = await this.$store.dispatch('visual/STORE_GET', {
        id,
      });

      this.fileLink = dataOssUrl;
      const a = document.createElement('a');
      a.href = dataOssUrl;
      a.download = '';
      document.body.appendChild(a);
      // window.open(dataOssUrl);
      this.visualUtils.wait().then(() => {
        a.click();
        document.body.removeChild(a);
        // this.$refs.exportFileLink.click();
      });
    },
  },

  created() {
    this.visualUtils.qrCode.toDataURL(location.href.replace('/visual', '/preview')).then(uri => (this.qr = uri));
  },
};
</script>
