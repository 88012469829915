export default ({ fluid }) => {
  // Exports
  return {
    // Configure of Interface (GUI)
    interface: {
      // Stage for Panel Adsorbent
      stage: {
        // Move Control
        moving: false,

        // Adsorbent Space
        adsorbent: {
          // Top Space
          top: {
            open: true,
            tab: false,
            min: 30,
            max: 60,
          },
          // Left Space
          left: {
            open: true,
            tab: true,
            min: 80,
            max: 240,
          },
          // Right Space
          right: {
            open: true,
            tab: false,
            min: 80,
            max: 320,
          },
          // Bottom Space
          bottom: {
            open: true,
            tab: false,
            min: 0, // 20,
            max: process.env.scaff.conf.copyright ? 30 : 0, // 40,
          },
          // Console Space
          console: {
            open: true,
            tab: false,
            min: 0,
            max: 0,
          },
        },

        // Distance Set
        distance: {
          // Top Bar
          top: [
            {
              name: 'toper',
            },
          ],
          // Left Aside
          left: [
            {
              name: 'material',
            },
            {
              ...(fluid ? { name: 'floor' } : { name: 'layer' }),
            },
          ],
          // Right Aside
          right: [
            {
              name: 'setting',
            },
            {
              name: 'optional',
            },
          ],
          // Bottom Bar
          bottom: [
            {
              ...(fluid ? { name: 'plus' } : { name: 'copyright' }),
            },
          ],
          // Console
          console: [],
        },
      },

      // 面板
      control: {
        visible: false,
      },
    },
  };
};
