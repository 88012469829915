<template>
  <div class="pickColor">
    <span v-if="value">{{ defaultColor }}</span>
    <!--    <a-button type="link" @click="resetColor">重置</a-button>-->
    <div class="colorBlock">
      <a-dropdown :trigger="['click']" :placement="'bottomRight'" v-model:visible="visible" @visibleChange="(stat) => {
        if(stat === false) {this.sketchWrapShow = false}
      }">
        <div class="colorBlock_content" :style="{background: chosenColor}" @click.prevent></div>
        <template #overlay>
          <div class="colorBlock_panel">
            <ul>
              <li v-for="(color, index) in presetColorArr" :key="color.key" @click="chooseColor(color.value,index)"
                  :class="presetColorActive===index?'active': ''">
            <span :style="{background: color.value}">
            </span>
              </li>
            </ul>
            <div class="colorBlock_panel_button" @click="sketchShowFunc">
              <h5>自定义配置</h5>
              <div class="colorBlock_sketchWrap" v-show="sketchWrapShow">
                <SketchPickColor :color="defaultColor" @getCol="handleColor" @click.stop/>
              </div>
            </div>
          </div>
        </template>
      </a-dropdown>
    </div>
    <div class="icon" @click="resetColor" v-if="reset">
      <ReloadOutlined/>
    </div>
  </div>
</template>

<script>
import SketchPickColor from "./sketch"
import {DEFAULT_COLOR, presetColorArr} from "./presetColor.js"
import {ReloadOutlined} from '@ant-design/icons-vue';

export default {
  name: "pickerColor",
  // reset: 重置颜色按钮
  // value: 显示当前颜色，便于复制
  props: ['reset', 'value', 'dColor'],
  data() {
    return {
      defaultColor: this.dColor ? this.dColor : DEFAULT_COLOR,
      initColor: this.dColor ? this.dColor : DEFAULT_COLOR,
      chosenColor: "",
      presetColorActive: 0,
      panelShow: false,
      sketchWrapShow: false,
      presetColorArr,
      visible: false
    }
  },
  mounted() {
    this.makeDefaultColor();
  },
  watch: {
    chosenColor(a) {
      this.defaultColor = a
    },
  },
  components: {
    SketchPickColor,
    ReloadOutlined
  },
  methods: {
    makeDefaultColor() {
      this.chosenColor = this.initColor
      this.$emit('setColor', this.initColor);
    },
    resetColor() {
      this.makeDefaultColor()
    },
    chooseColor(color, index) {
      this.chosenColor = color;
      this.presetColorActive = index;
      this.$emit('setColor', color);
    },
    sketchShowFunc() {
      this.sketchWrapShow = !this.sketchWrapShow
    },
    handleColor(colorVal) {
      this.chosenColor = colorVal
      console.log(89, colorVal)
      this.$emit('setColor', colorVal);
    }
  },
}
</script>

<style scoped lang="less">
.pickColor {
  text-align: right;

  & > span {
    display: inline-block;
    width: 60px;
    vertical-align: top;
    margin-top: 5px;
    margin-right: 15px;
  }

  .icon {
    display: inline-block;
    box-sizing: border-box;
    width: 46px;
    vertical-align: top;
    height: 30px;
    margin-top: 0;
    padding-top: 7px;
    cursor: pointer;
    text-align: center;

    &:hover {
      span {
        transform: rotate(180deg);
      }
    }

    span {
      transition: all .3s;
      vertical-align: top;
    }
  }

  .colorBlock {
    display: inline-block;
    background-color: #E9E7EC;
    padding: 5px;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    vertical-align: top;

    .colorBlock_content {
      width: 50px;
      height: 20px;
      //background-color: red;
      border-radius: 2px;
    }
  }
}

.colorBlock_panel {
  width: 200px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  padding-top: 5px;
  cursor: pointer;

  ul {
    list-style: none;
    padding: 0;
    text-align: left;
    margin: 16px;

    li {
      display: inline-block;
      width: 32px;
      height: 32px;
      border: 1px solid transparent;
      margin-right: 13px;
      margin-bottom: 13px;
      text-align: center;

      &.active {
        border: 1px solid blue;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }

      span {
        display: inline-block;
        vertical-align: top;
        width: 22px;
        height: 22px;
        border: 1px solid #ccc;
        margin-top: 4px;
      }
    }
  }

  .colorBlock_panel_button {
    height: 45px;
    line-height: 45px;
    border-top: 1px solid rgba(#ccc, 0.3);

    h5 {
      text-align: center;
    }
  }

  .colorBlock_sketchWrap {
    width: 250px;
    border: 1px solid #ccc;
    position: absolute;
    top: -100px;
    right: 56px;
  }
}
</style>
