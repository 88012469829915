export default ({ wait }) => {
  // Exports
  return {
    /**
     * History Undo >>>
     * ========== ========== ==========
     */
    async HISTORY_UNDO({ state, dispatch }) {
      // Set Lock First
      state.history.lock = true;

      // Set Max
      const max = state.history.cache.length - 1;

      // Caculator Up
      if (state.step < max) {
        state.step++;
      }

      // Wait 100
      wait(360).then(() => {
        state.history.lock = false;
      });

      // Update
      return {
        // step: state.step,
      };
    },

    /**
     * History Redo <<<
     * ========== ========== ==========
     */
    async HISTORY_REDO({ state, dispatch }) {
      // Set Lock First
      state.history.lock = true;

      // Set Min
      const min = state.history.cache.length ? 0 : -1;

      // Caculator Down
      if (state.step > min) {
        state.step--;
      }

      // Wait 100
      wait(360).then(() => {
        state.history.lock = false;
      });

      // Update
      return {
        // step: state.step,
      };
    },

    /**
     * History Snapshot
     * ========== ========== ==========
     */
    async HISTORY_SNAPSHOT({ state, dispatch }) {
      // No Work as Lock
      if (state.history.lock !== false) {
        return;
      }

      // Undo First
      // await dispatch('HISTORY_UNDO');

      // Get Step
      const { history, store } = state;

      // Get Compare
      const compare = history.max - 1;

      // Set Step
      const step = state.step < compare ? state.step + 1 : state.step;

      // Get Cache
      let cache = [].concat(history.cache);

      // Get Length
      const length = cache.length;

      // Get Cloner
      const cloner = _.cloneDeep(store);

      // Checker
      const check = step < compare; // step === history.max;

      // Clean Cache
      // cache.splice(check ? 0 : step, length - step + 1);
      cache = cache.slice(check ? 0 : 1, step + 1);

      // Make Step
      cache.push(cloner);

      // Update
      return {
        // Lenth of New Cache
        step: cache.length - 1,
        // New Cache
        history: {
          cache,
        },
        // unLock
        lock: false,
      };
    },

    /**
     * History Load as Clone
     * ========== ========== ==========
     */
    async HISTORY_LOAD({ state, dispatch }) {
      state.store = [
        {
          context: [],
          env: {},
          id: state.store[0].id,
        },
      ];

      return {
        store: _.cloneDeep(state.history.cache[state.step]),
        history: {
          lock: false,
        },
      };
    },

    /**
     * History Clean
     * ========== ========== ==========
     */
    async HISTORY_CLEAN({ state }) {
      // Clean Cache of History
      return {
        // Step
        step: -1,

        // History
        history: {
          // No Cache
          cache: [],
          // unLock
          lock: false,
        },
      };
    },
  };
};
