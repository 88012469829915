// Use VueDragResizeMotion
import { motionPlugin } from 'vue-drag-resize-motion';

// Use SDK Requirer
import requirer from '@skylla/sdk/requirer';

// Use SDK Vue
import { dragger } from '@skylla/sdk/vue';

// Use Components Any
const Components = require.context('./src/components', true, /\.vue$/);

// Set Install for `app.use`
function install(app, options = {}) {
  // If is Installed
  if (install.installed) {
    return;
  }

  // is Install
  install.installed = true;

  // Register Vue Draggable
  app.component('dragger', dragger);

  // Register VueDragResizeMotion
  app.use(motionPlugin);

  // Register Components
  requirer(Components, app);

  // Set Preview for Materials
  app.config.globalProperties.preview = /preview$/.test(location.pathname);
}

/**
 * Export as API
 * ========== ========== ==========
 */

// Export Install
export default install;
