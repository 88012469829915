<style lang="less" scoped>
.scrolling {
  width: 100%;
  height: auto;

  overflow-x: hidden;
  overflow-y: auto;
}
</style>

<template>
  <div :style="css">
    <div class="scrolling">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    css() {
      return {
        paddingTop: `${this.adsorbent.top.max}px`,
        paddingLeft: `${this.adsorbent.left.max}px`,
        paddingRight: `${this.adsorbent.right.max}px`,
        paddingBottom: `${this.adsorbent.bottom.max}px`,
      };
    },
  },
};
</script>
