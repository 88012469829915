<style lang="less" scoped>
.accessory {
  z-index: 9999999;
  position: relative;
  width: 40px;
  text-align: center;
  // padding-bottom: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  color: #626262;
  border-radius: 2px;
}
.widget--name__confirm {
  display: block;
  margin-left: 70%;
}
.edit {
  & {
    border-top-right-radius: 2px;
    padding: 12px 0;
    display: block;
    cursor: pointer;
    transition: 0.6s;
  }
  &:hover {
    background: rgb(190, 200, 200);
    // color: #fff;
  }
}
.del {
  & {
    border-bottom-right-radius: 2px;
    padding: 12px;
    display: block;
    cursor: pointer;
    transition: 0.6s;
  }
  &:hover {
    background: rgb(190, 200, 200);
    // color: #fff;
  }
}
</style>

<template>
  <div class="accessory">
    <a-tooltip placement="right">
      <template #title>
        <span>更名</span>
      </template>
      <a-popover v-model:visible="visible" title="" trigger="click">
        <template #content>
          <a-form-item label="名字">
            <a-input v-model:value="element.meta.label" />
          </a-form-item>
          <!-- <a-button @click='onEditComponent' class="widget--name__confirm" type="primary">确定</a-button> -->
        </template>
        <FormOutlined class="edit" />
      </a-popover>
    </a-tooltip>
    <a-tooltip placement="right">
      <template #title>
        <span>删除</span>
      </template>
      <!-- <FormOutlined class="edit"  @click='onEditComponent' /> -->
      <ClearOutlined class="del" @click="onDelComponent" />
    </a-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    element: {
      type: [Object],
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    onEditComponent(item) {
      this.visible = false;
      console.log(item);
    },
    onDelComponent(item) {
      this.$store.dispatch('visual/COMPONENT_REMOVE', this.visual.active);
      //COMPONENT_ALL
      setTimeout(() => {
        this.$store.update('visual', {
          active: 0,
        });
      });
    },
  },
};
</script>
