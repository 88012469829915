// Use Font Awesome -- Core
import { library } from '@fortawesome/fontawesome-svg-core';

// Use Font Awesome -- Solid Style
import * as solidIcons from '@fortawesome/free-solid-svg-icons';

// Use Utils
import * as utils from './index';

// Get Utils
const { loader, cssfile, qs, loop, each, wait, capture } = utils;

/**
 * Materials Load
 * @param namespace { string }
 * ========== ========== ==========
 */
export function materialsLoad(options = {}, callback = () => {}, namespace = 'materials-printer') {
  // Get Query
  const { debug, material, version = options.version || 'latest', port = 3000 } = qs();

  // Material or Debug for Namespace
  namespace = material || debug || namespace;

  // Debug for Host
  const host = debug ? `http://localhost:${port}/dist` : `https://skylla.oss-cn-shanghai.aliyuncs.com/${namespace}`;

  // Load CSS
  cssfile(`${host}/${version}/${namespace}.css`);

  // Load Material
  loader(`${host}/${version}/${namespace}.umd.min.js`, (error, script) => {
    // Debugger
    $console.log('Materials Load:', script);

    // Callback as Init
    callback();
  });
}

/**
 * Get Packages
 * @param packages { context }
 * @param callback { function }
 * ========== ========== ==========
 */
export const getPackages = (packages, callback = () => {}, depender = {}) => {
  // Loop Keys of Packages
  packages.keys().forEach(key => {
    // Out Of Index
    if (/index/.test(key)) {
      return;
    }

    // Set as Depender from Callback
    Object.assign(depender, callback(packages(key).default));
  });

  // Result
  return depender;
};

/**
 * Prop Extension
 * @param app { object }
 * @param props { json }
 * ========== ========== ==========
 */
export const propExtension = (app, props = {}) => {
  // Use Assign for Extension
  Object.assign(app.config.globalProperties, props);
};

/**
 * Package Use
 * @param app { object }
 * @param packages { array }
 * ========== ========== ==========
 */
export const packageUse = (app, packages = []) => {
  // Use Module as Loop
  loop(packages, m => app.use(m));
};

/**
 * Register
 * @param components { object }
 * @param app { object }
 * ========== ========== ==========
 */
export const register = (components = {}, app) => {
  each(components, (component, namespace) => {
    app.component(namespace, component);
  });
};

/**
 * Insert Icons into Library with Timeout
 * ========== ========== ==========
 */
export async function initFontAwesomeIcons() {
  // Set Cache
  const cache = Object.values(solidIcons);
  // Set Length
  let length = cache.length;
  // Set Rate
  let rate = 10;

  // While
  for (let index = 0; index < length / rate; index++) {
    wait(0, 1, () => {
      const icons = cache.slice(index * rate, (index + 1) * rate).filter(icon => !!icon.prefix);
      library.add(...icons);
    });
  }

  // Exports
  return library;
}
