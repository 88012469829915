export default ({ $http, api, qs, wait }) => {
  // Exports
  return {
    /**
     * Data Render
     * ========== ========== ==========
     */
    async DATA_RENDER({ state, dispatch }, { context, data }) {
      // Get Poster and Trigger
      const { poster, trigger } = context;

      // Get Resource and Content
      const { resource, content } = data;

      // Trigger Resource
      await dispatch('RESOURCE_SYNC', { resource, poster });

      // Trigger Content
      await dispatch('CONTENT_CATCH', { content, poster, trigger });

      // Endness
      return;
    },

    /**
     * Data Workflow
     * ========== ========== ==========
     */
    async DATA_WORKFLOW({ state, commit, dispatch }, { data, check, trigger = () => {} }) {
      if (check) {
        await dispatch('COMPONENT_ALL', component => {
          component.source = data;
        });
      }

      // Trigger
      wait(100).then(() => trigger());
    },

    /**
     * Resource Presetting
     * ========== ========== ==========
     */
    async RESOURCE_SYNC({ state, commit, dispatch }, { resource, poster, trigger } = {}) {
      // Check $resource for Node
      if (window.$injection) {
        // Get Resource
        resource = window.$injection.resource;

        // Workflow Running
        dispatch('DATA_WORKFLOW', { data: resource, check: !state.preview, trigger });

        // Export
        return { resource };
      }

      // Get Resource HTTP
      resource = resource || (await $http(api.resourceGet).get({ ...qs }));

      // Workflow Running
      dispatch('DATA_WORKFLOW', { data: resource, check: !state.preview, trigger });

      // Update Resource for Useage
      return {
        resource,
      };
    },

    /**
     * Content Catching
     * ========== ========== ==========
     */
    async CONTENT_CATCH({ state, commit, dispatch }, { content, poster, trigger = () => {} } = {}) {
      // Check $resource for Node
      if (window.$injection) {
        // Get Content
        content = window.$injection.content;

        // Workflow Running
        dispatch('DATA_WORKFLOW', { data: content, check: state.preview, trigger });

        // Export
        return { content };
      }

      // Get Contents
      content = content || (await $http(api.contentGet).get({ ...qs }));

      // Workflow Running
      dispatch('DATA_WORKFLOW', { data: content, check: state.preview, trigger });

      // Update Content for Useage
      return {
        content,
      };
    },
  };
};
