// Use Sentry Config as Scaff
// const { sentry } = process.env.scaff.conf;

// Use Antv
import * as Antv from 'ant-design-vue';
import * as Icons from '@ant-design/icons-vue';

// Use Visual
import * as ENG from '@skylla/engine';
import * as GUI from '@skylla/gui';

// Get visualPlugin and visualStore
const { visualPlugin, visualStore, visualGui, visualHook } = ENG;

// Use Sentry
// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
/**
 * Scaffold by Joenix
 * ========== ========== ==========
 */
export default ({ app, util, route, store, component, style, i18n, $http, custom }, next) => {
  // Use Some Utils
  const { foreach, qs } = util.get();

  // Extension Antv
  app.use(Antv);

  // Register Icons
  foreach(Icons, (icon, key) => app.component(key, icon));

  // Extension Icons
  app.config.globalProperties.$icons = Icons;

  // Route Configure
  route.proxy.config = routes => {
    return {
      mode: 'history',
      base: '/',
    };
  };

  // Extension Route
  route.proxy.extension = router => {
    if (/debug/.test(location.search)) {
      return;
    }
  };

  // Capture Interceptor with Sentry process == engine
  app.captureInterceptor = ({ process, error, instance }) => {
    console.log('error is:', process, error, instance);
  };

  store.proxy.config = modules => {
    modules.visual = visualStore(util.get());
  };

  // Http BodyParse
  $http.interceptor.bodyParse = params => new URLSearchParams(params);

  // Http Interceptor
  $http.interceptor.request =  (request) => {
    // Set Content Type
    request.headers.set('Content-Type', 'application/x-www-form-urlencoded');

    // Set Credentials
    request.headers.set('Access-Control-Allow-Credentials', true);

    // Hook for Frame
    if (window.Authorization) {
      request.headers.set('Authorization', window.Authorization);
    }

    if (qs[`saas-token`]) {
      request.headers.set('saas-token', qs[`saas-token`]);
    }
  };

  // $http.interceptor.response = async (response) => {
  // };
  //
  // $http.interceptor.error = async e => {
  //   return $console.error(e);
  // };

  // Use Gui
  visualGui(GUI);

  // Use Plugin of Visual
  app.use(visualPlugin, {
    // Next
    next,
  });
};
