<template>
  <slot />
</template>

<script>
export default {
  props: {
    watch: {
      type: [Object],
      default() {
        return {};
      },
    },
  },

  watch: {
    watch: {
      handler(store) {
        // this.$store.dispatch('visual/HISTORY_SAVE');
      },
      deep: true,
    },
  },
};
</script>
