<style lang="less">
@import './icon.less';

// .iconcite {
//   display: inline-block;
//   width: 16px;
//   height: 16px;
// }
</style>

<template>
  <!-- <i v-if="src" class="iconcite" :style="{ ...style, ...css }" /> -->
  <!-- <i v-else class="iconfont" :class="`icon-${mode}`" /> -->
  <i class="iconfont" :class="`icon-${mode}`" :style="{ ...css }" />
</template>

<script>
export default {
  props: {
    mode: {
      type: [String],
      default() {
        return '';
      },
    },

    src: {
      type: [String],
      default() {
        return '';
      },
    },

    css: {
      type: [Object],
      default() {
        return {};
      },
    },
  },

  // computed: {
  //   style() {
  //     return {
  //       backgroundImage: `url(${this.src})`,
  //       backgroundSize: `contain`,
  //       backgroundPosition: `center`,
  //       backgroundRepeat: `no-repeat`,
  //     };
  //   },
  // },
};
</script>
