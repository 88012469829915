const DEFAULT_COLOR = "#757575";

const RED = {
  key: 0,
  value: "#FF2525"
};

const ORANGE = {
  key: 1,
  value: "#FFA725"
};

const YELLOW = {
  key: 2,
  value: "#FFD925"
};

const DEEP_GREY = {
  key: 3,
  value: "#595961"
};

const GREY = {
  key: 4,
  value: "#9797A1"
};

const LIGHT_GREY = {
  key: 5,
  value: "#BBBBBB"
};

const WHITE = {
  key: 6,
  value: "#FFFFFF"
};

const BLACK = {
  key: 7,
  value: "#000000"
}

const presetColorArr = [
  RED,
  ORANGE,
  YELLOW,
  DEEP_GREY,
  GREY,
  LIGHT_GREY,
  WHITE,
  BLACK
]

export {DEFAULT_COLOR, presetColorArr}

// export presetColorArr;
