<template>
  <div class="grid" v-if="resource" :id="id">
    <visual-page v-for="(source, key) in store" :key="source.id" :index="key" v-if="visual.effect">
      <template #default="{ size, unit }">
        <!-- Parts -->
        <visual-parts v-if="ins" :source="source.context">
          <template #default="{ item }">
            <visual-item :watch="item.option">
              <component :is="item.name" :option="item.option" :source="item.source" :vars="item.vars" :axes="item.axes" />
            </visual-item>
          </template>
        </visual-parts>

        <!-- Fence -->
        <visual-fence v-else :parent="size" :source="source.context" :offset="offset.main" :unit="unit" :space="vertexHeader.space">
          <template #default="{ item }">
            <visual-item :watch="item.option">
              <component :is="item.name" :option="item.option" :source="item.source" :vars="item.vars" :axes="item.axes" />
            </visual-item>
          </template>
        </visual-fence>
      </template>

      <template #header="{ size, unit }">
        <!-- Fence -->
        <visual-fence :parent="size" :source="vertexes.header" :offset="offset.header" :unit="unit" :space="0">
          <template #default="{ item }">
            <visual-item :watch="item.option">
              <component :is="item.name" :option="item.option" :source="item.source" :vars="item.vars" :axes="item.axes" />
            </visual-item>
          </template>
        </visual-fence>
      </template>

      <template #footer="{ size, unit }">
        <visual-fence :parent="size" :source="vertexes.footer" :offset="offset.footer" :unit="unit" :space="vertexHeader.space + vertexMain.space">
          <template #default="{ item }">
            <visual-item :watch="item.option">
              <component :is="item.name" :option="item.option" :source="item.source" :vars="item.vars" :axes="item.axes" />
            </visual-item>
          </template>
        </visual-fence>
      </template>
    </visual-page>
  </div>
</template>

<script>
export default {
  props: {
    ins: {
      type: [Boolean],
      default() {
        return false;
      },
    },

    store: {
      type: [Object],
      default() {
        return [];
      },
    },

    id: {
      type: [String],
      default() {
        return '';
      },
    },
  },

  computed: {
    // Offset on Dragger
    offset() {
      // Active Offset as Effect
      const offset = this.visual.effect;

      // X Coordinate and Preset Padding
      const x = offset.x + this.setting.padding.left;
      // Y Coordinate and Preset Padding
      const y = offset.y + this.setting.padding.top;

      // Space as Visible with Header of Vertex
      const space = this.vertexHeader.visible ? this.vertexHeader.space : 0;

      // Offset Calculator
      return {
        header: { x, y },
        main: { x, y: y + space * this.one.y },
        footer: { x, y: y + (space + this.vertexMain.space) * this.one.y },
      };
    },
  },
};
</script>
