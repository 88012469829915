export default ({ loop }) => {
  // Exports
  return {
    /**
     * Component All Get
     * ========== ========== ==========
     */
    async COMPONENT_ALL({ state }, callback = () => {}) {
      // Set Components
      const components = [];

      // Loop Context
      loop(state.store[0].context.flat(), component => {
        components.push(component);
        callback(component);
      });

      // Loop Header
      loop(state.vertexes.header.flat(), component => {
        components.push(component);
        callback(component);
      });

      // Loop Footer
      loop(state.vertexes.footer.flat(), component => {
        components.push(component);
        callback(component);
      });

      // Export Components
      return components;
    },

    /**
     * Component Delete
     * ========== ========== ==========
     */
    async COMPONENT_REMOVE({ state, dispatch }, { id }) {
      // No Active
      if (id) {
        state.active = null;
      }

      // Filter Function
      const remove = (components = [], id) => {
        // No Components
        if (!components.length) {
          return components;
        }

        // Get First Component
        const first = components[0];

        // Fluid Mode
        if (first.constructor === Array) {
          components = components.map(component => remove(component, id));
        }

        // Grid Mode
        else {
          components = components.filter(component => !(component.id === id));
        }

        // Endness
        return components;
      };

      // Hard in Context First
      state.store[0].context = remove(state.store[0].context, id);

      // Hard Foreach
      state.vertexes.header = remove(state.vertexes.header, id);
      state.vertexes.footer = remove(state.vertexes.footer, id);
    },

    /**
     * Clean All of Actives
     * ========== ========== ==========
     */
    async CLEAN_ACTIVE_ALL({ state }, without) {
      // Every Page
      loop(state.store, page => {
        // Loop Parts
        loop(page.context, item => {
          item.active = false;
        });

        loop(page.vertexes.header, item => {
          item.active = false;
        });

        loop(page.vertexes.footer, item => {
          item.active = false;
        });
      });
    },

    /**
     * Clean First of Actives
     * ========== ========== ==========
     */
    CLEAN_ACTIVE_FIRST({ state }) {
      // Get First
      const first = state.store[0] || [];

      // Every Groove
      loop(first, groove => {
        // Loop Groove
        loop(groove, item => {
          item.active = false;
        });
      });
    },
  };
};
