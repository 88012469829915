<style lang="less" scoped>
.part {
}
</style>

<template>
  <a-collapse-panel :key="key">
    <template #header>{{ title }}</template>
    <a-form layout="vertical">
      <slot />
    </a-form>
  </a-collapse-panel>
</template>

<script>
export default {
  props: {
    title: {
      type: [String],
      default() {
        return '';
      },
    },
    key: {
      type: [String, Number],
      default() {
        return new Date().getTime();
      },
    },
  },
};
</script>
