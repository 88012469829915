<style lang="less" scoped>
.select-bg-upload-image {
  display: flex;
  height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;

  .preview-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    img {
      height: 100%;
      width: 50px;
    }
    .imgFile {
      font-size: 20px;
    }
  }

  .operate-group {
    flex: 1;
    display: flex;
    .item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      .image-uploader {
        width: 100%;
        :deep(.ant-upload) {
          display: block;
          overflow: hidden;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          background: #fff;
          border: none;
          .anticon {
            vertical-align: -0.425em;
          }
          .anticon-loading {
            vertical-align: -1em;
          }
        }
      }
      .text {
        line-break: 1.1;
      }
    }
    .select-contain {
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
      flex-direction: column;
    }
  }
  :deep(.ant-btn-block) {
    padding-left: 11px;
    padding-right: 11px;
    .text {
      overflow: hidden;
      float: left;
      width: 62px;
      height: 22px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      color: rgba(0, 0, 0, 0.85);
    }
    &:focus {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
.upload-image-wrap {
  padding: 12px 12px 16px 12px;
  width: 245px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  &__head {
    margin-bottom: 10px;
    font-size: 13px;
    color: #1d222a;
    line-height: 18px;
  }
}
</style>
<style>
.ant-dropdown {
  z-index: 999 !important;
}
</style>

<template>
  <div class="select-bg-upload-image">
    <div class="operate-group">
      <div class="item upload">
        <a-upload accept=".jpg, .jpeg, .png, .bmp" :before-upload="beforeUpload" v-model:file-list="fileList" name="file" list-type="picture-card" class="image-uploader" :show-upload-list="false" :headers="headers" :data="uploadData" :action="actionUrl" @change="handleChange">
          <div class="select-contain">
            <loading-outlined v-if="loading"></loading-outlined>
            <template v-else>
              <UploadOutlined />
              <div class="text">上传</div>
            </template>
          </div>
        </a-upload>
      </div>
      <div class="item select">
        <a-dropdown placement="bottomCenter" :trigger="['click']" style="z-index: 999">
          <div class="select-contain">
            <CheckSquareOutlined />
            <span class="text">选取</span>
          </div>
          <template #overlay>
            <div class="upload-image-wrap">
              <p class="upload-image-wrap__head">素材库</p>
              <image-list :list="backgroundImageList" :loadImg="loadBgImgList"></image-list>
            </div>
          </template>
        </a-dropdown>
      </div>
      <div class="item delete" @click="handleDelete">
        <ClearOutlined />
        <div class="text">清除</div>
      </div>
    </div>
  </div>
</template>

<script>
import imageList from './image-list';

export default {
  name: 'SelectUploadImage',
  components: {
    imageList,
  },
  props: {
    title: {
      type: [String],
      default() {
        return '';
      },
    },
  },

  data() {
    return {
      fileList: [],
      backgroundImageList: [],
      loading: false,
      actionUrl: '',
      headers: {
        Authorization: window.Authorization,
      },
      uploadData: {
        ossType: '1',
      },
    };
  },

  computed: {
    setting() {
      return this.visual.setting;
    },
  },

  created() {
    this.actionUrl = this.visualApis.ossUpload;
  },

  mounted() {
    this.loadBgImgList();
  },

  methods: {
    loadBgImgList(tragger = false) {
      this.visualUtils.wait(1000).then(async () => {
        const result = await this.$store.dispatch('visual/OSS_LIST', { ossType: 1, tragger });
        if (result) {
          this.backgroundImageList = result;
        }
      });
    },

    handleChange(info) {
      const { status, response } = info.file;
      console.log(response);
      const t = this;
      ({
        uploading() {
          t.loading = true;
        },
        done: function () {
          this.loading = false;
          const { data, errorMessage } = response;
          if (data) {
            this.loadBgImgList(true);
            this.fileList = [];
            this.$message.success('上传成功');
          } else {
            this.$message.error('上传失败，原因为：' + errorMessage);
          }
        }.bind(this),
        error() {
          t.loading = false;
          t.$message.error('上传失败');
        },
      }[status]());
    },

    beforeUpload() {
      console.log('gui window.Authorization', window.Authorization);
      this.headers.Authorization = window.Authorization;
      return true;
    },

    async handleDelete() {
      const backgroundActive = this.setting.backgroundActive || {};
      if (!backgroundActive.id) return this.$message.warning(`请先选中图片再进行删除。`);
      this.setting.backgroundCss.url = '';
      this.setting.backgroundActive = {};
    },
  },
};
</script>
