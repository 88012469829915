<style lang="less" scoped></style>

<template>
  <a-modal v-model:visible="visible" title="系统设置">
    <a-form>
      <a-form-item label="历史步骤">
        <a-space>
          <a-input-number v-model:value="visual.history.steps" :min="20" :max="100" />
          <span>20 - 100</span>
        </a-space>
      </a-form-item>

      <a-form-item label="PAGE STORE">
        <a-button type="primary" @click="storeListGet">点击获取全量 STORE</a-button>
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button type="primary" @click="success">确定</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: [Boolean],
      default() {
        return false;
      },
    },
  },

  methods: {
    success() {
      this.$store.update('visual', {
        interface: {
          control: {
            visible: false,
          },
        },
      });
    },

    async storeListGet() {
      const stores = await this.$store.dispatch('visual/STORE_LIST');

      $console.log('FULL STORE: ', stores);
    },
  },
};
</script>
