<style lang="less">
:root {
  overflow: auto;
  overscroll-behavior-x: none;
}

#print {
  & {
    pointer-events: none;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .page {
    overflow: hidden;
    box-shadow: none !important;

    &--auxiliary {
      display: none !important;
    }
  }

  .fluid {
    &--groove {
      border-color: transparent !important;

      &--anchor {
        display: none !important;
      }
    }
  }

  &.mobox {
    height: auto !important;
    padding: 0 !important;
  }
}
</style>

<template>
  <!-- Fluid or Simulator -->
  <visual-simulator :class="{ finish }" :ins="true" :store="storage" id="print" />
</template>

<script>
// Use Print
import Printer from 'a-print';

import { bindMessage, sendMessage, removeFrame, print, preview, pdf } from '@skylla/sdk/api';

export default {
  data() {
    // Preset Scope
    const that = this;

    // Get Query
    const query = that.visualUtils.qs();

    // Set Data
    return {
      // Finish
      finish: false,

      // Options of Print
      options: {
        // css: `.page { background: none !important; }`,

        // Callback Before
        onPrintProcessReady() {
          // Update Process of Print
          that.$store.update('visual', { printer: true });

          // Send Message as Print Process
          sendMessage({ mode: 'onPrintProcessReady' });
        },

        // Callback After
        onPrintProcessOpen() {
          // Update Process of Print
          that.$store.update('visual', { printer: false });

          // Send Message as Print Process
          sendMessage({ mode: 'onPrintProcessOpen' });
        },

        // Callback Close
        onPrintProcessClose() {
          // Send Message as Print Process
          sendMessage({ mode: 'onPrintProcessClose' });

          // Send Clean
          try {
            that.visualUtils.wait(0).then(() => that.clean());
          } catch (e) {}
        },
      },
    };
  },

  computed: {
    // `collections` is keyword of repeat
    repeats() {
      // Get Content
      const content = this.visual.content || {};

      // Tolence of Collections
      content.collections = content.collections || {};

      // Export Data Repeats
      return (this.repeat.name ? content.collections[this.repeat.name] : content.collections) || [];
    },

    length() {
      return Math.ceil(this.repeats.length / this.repeat.limit);
    },

    storage() {
      if (!this.fluid) {
        for (let i = 0; i < this.length; i++) {
          this.complex(i, !!i);
        }
      }

      // Return
      return this.keeper;
    },
  },

  methods: {
    // Complex for Repeat Module
    complex(index, clone = true, element) {
      // No Keeper
      if (!this.keeper.length) {
        return;
      }

      // Get Page
      const page = clone ? _.cloneDeep(this.first) : this.first;

      // Get Element First
      page.context.forEach(component => {
        // Set Repeat as Repeat in Meta
        if (component.meta.repeat) {
          component.option.repeatIndex = index;
        }
      });

      // Push
      if (clone) {
        this.keeper.push(page);
      }
    },

    // Trigger Printer
    trigger() {
      if (this.query.print === 'false') {
        return;
      }

      // Stop Use
      return;

      Printer('#print', this.options);
    },

    // Clean Frame
    clean() {
      // Get Frame Wrapper
      const wrapper = global.parent;

      // Get Element Frame
      const frame = wrapper.document.querySelector('#visual-preview-iframe');

      // Stack Async
      this.visualUtils.wait(0).then(() => wrapper.document.body.removeChild(frame));

      // Remove Content
      document.write('');

      // Close Document
      document.close();

      // Redirect Blank
      global.location.href = 'about:blank';
    },

    // Time Round
    async round(callback) {
      // Take Round
      this.visualUtils.round(100, async () => {
        // No Finish No Endness
        if (!this.finish) {
          return;
        }

        // Handler
        await callback(this);

        // Break;
        return true;
      });
    },

    // Render
    async render(data = {}) {
      // Stylesheet
      await this.visualUtils.stylesheet(data);

      // Init
      data.local
        ? // Use Local in Editor Env
          await this.$store.update('visual', {
            ...data.local,
          })
        : // Get Store from API
          // await this.$store.dispatch('visual/STORE_INIT');
          await this.$store.dispatch('visual/PFS_GET');

      // Get Data
      await this.$store.dispatch('visual/DATA_RENDER', { context: this, data });

      // Get Params from Data for Special Useage
      const { token, merge } = data;

      // Special Title from Parent
      if (merge) {
        this.$store.update('visual', {
          ...merge,
          token,
        });
      }

      // Stop
      return;
    },

    // PFormatter
    async pformatter() {
      // Use Promise
      return new Promise(resolve => {
        // Async
        this.visualUtils.wait(100).then(async () => {
          // Get Target -- Must
          const target = document.querySelector('#print');

          // Dispatch PDF Render
          await this.$store.dispatch('visual/PDF_RENDER', target);

          // Resolve
          resolve();
        });
      });
    },
  },

  async created() {
    // Set That as this
    const that = this;

    // As Poster from SDK
    if (that.poster) {
      // Bind Event Message
      return await bindMessage({
        // Print
        async onPrintReady({ mode, data }) {
          // Take Round
          that.round(async that => {
            // Set Token First
            window.Authorization = data.token;

            // Render
            await that.render(data);

            // Send Complete
            await sendMessage({ mode: 'onPrintComplete', data });
          });
        },

        // Preview
        async onPreviewReady({ mode, data }) {
          // Take Round
          that.round(async that => {
            // Set Token First
            window.Authorization = data.token;

            // Render
            await that.render(data);

            // Send Complete
            await sendMessage({ mode: 'onPreviewComplete', data });
          });
        },

        // PDF
        async onPDFReady({ mode, data }) {
          // Take Round
          that.round(async that => {
            // Set Token First
            window.Authorization = data.token;

            // Render
            await that.render(data);

            // PFormatter
            await that.pformatter();

            // Send Complete
            await sendMessage({ mode: 'onPDFComplete', data });
          });
        },
      });
    }
  },

  // Mounted as Init
  async mounted() {
    // No Poster
    if (this.poster) {
      // Set Finish as Render for Server
      this.$nextTick(() => {
        // Set Finish
        this.finish = true;
      });

      // Stop
      return;
    }

    // Arguer Render
    await this.render();

    // Set Finish as Render for Server
    this.finish = true;

    // As PDF
    if (this.query.pdf && this.query.pdf !== 'false') {
      // PFormatter
      await this.pformatter();
    }
  },
};
</script>
