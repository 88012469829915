<style lang="less" scoped>
.taber {
}
</style>

<template>
  <div class="taber">先放着，等我有空了重构一个</div>
</template>

<script>
export default {};
</script>
