<template>
  <!-- Mode as Query.Mode: Grid or Fluid or Mobox -->
  <component :is="stitute" :store="store" :id="id" :ins="ins" />
</template>

<script>
export default {
  props: {
    id: {
      type: [String],
      default() {
        return '';
      },
    },
    store: {
      type: [Object],
      default() {
        return {};
      },
    },
    ins: {
      type: [Boolean],
      default() {
        return false;
      },
    },
  },

  computed: {
    stitute() {
      return `visual-${this.modern}`;
    },
  },
};
</script>
