<style lang="less" scoped>
.plus {
  width: 180px;
  margin-left: -90px;

  left: 50%;
  bottom: 20px;
  position: fixed;

  &--button {
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 20px;
    background-color: #00a6c9;
  }
}
</style>

<template>
  <div class="plus" v-if="fluid">
    <a-button type="primary" class="plus--button" @click="onPlus">
      <PlusOutlined />
      新增楼层
    </a-button>
  </div>
</template>

<script>
export default {
  methods: {
    onPlus() {
      // Preset Item as Empty
      const item = [];

      // Set Order as Time
      item.order = new Date().getTime();

      // Set into Context
      this.keeper[0].context.push(item);
    },
  },
};
</script>
