export default ({}) => {
  // Exports
  return {
    /**
     * Default Page Create
     * ========== ========== ==========
     */
    async PAGE_CREATE({ state }, data = {}) {
      // Set Store
      const store = _.cloneDeep(state.preset.formatter);

      // Set Id as Time
      store.id = `page_${new Date().getTime()}`;

      // Return
      return {
        store: [store],
      };
    },
  };
};
