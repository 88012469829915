<style lang="less" scoped>
.footer {
  height: 100%;
}
</style>

<template>
  <visual-gui-flex class="footer" content="center">
    <slot />
  </visual-gui-flex>
</template>

<script>
export default {};
</script>
