export default ({ modern, fluid, screen }) => {
  const page = {
    grid: {
      custom: [null, null],

      A0: [841, 1189],
      A1: [594, 841],
      A2: [420, 594],
      A3: [297, 420],
      A4: [210, 297],
      A5: [148, 210],
      A6: [105, 148],
      A7: [74, 105],
      A8: [52, 74],

      B0: [1030, 1456],
      B1: [728, 1030],
      B2: [515, 728],
      B3: [364, 515],
      B4: [257, 364],
      B5: [182, 257],
      B6: [128, 182],
      B7: [91, 128],
      B8: [64, 91],
    },
    fluid: {
      custom: [null, null],

      A3: [297, 420],
      A4: [210, 297],
    },
    mobox: {
      custom: [null, null],

      samsungGalaxyS8: [360, 720],
      iphone8: [375, 667],
      iphoneXR: [414, 896],
      iphoneX: [375, 812],
      iphone13pro: [390, 884],
      iphone13promax: [428, 926],
      iphoneSE: [375, 667],
      iphone12pro: [90, 844],
    },
  }[modern];

  return {
    // Preset
    preset: {
      // Diff for

      // Default Formatter
      formatter: {
        // Identity
        id: 0,

        // Environment
        env: {},

        // Context
        // context: modern === 'fluid' ? [[], [], []] : [],
        context: modern === 'fluid' ? [[], [], []] : [],
      },

      // Screen Info
      screen: {
        w: screen.clientWidth,
        h: screen.clientHeight,
      },

      // Page Size
      page,

      // Mobile Size
      mobile: {
        iphoneSE: {
          width: 375,
          height: 667,
        },
        iphoneXR: {
          width: 414,
          height: 896,
        },
        iphone12pro: {
          width: 390,
          height: 844,
        },
      },

      // Vertex
      vertex: {
        header: [
          {
            label: '不展示',
            value: 0,
          },
          // {
          //   label: '仅在首页展示',
          //   value: 1,
          // },
          {
            label: '展示', // '每页展示',
            value: 2,
          },
        ],
        footer: [
          {
            label: '不展示',
            value: 0,
          },
          // {
          //   label: '仅在末页展示',
          //   value: 1,
          // },
          {
            label: '展示', // '每页展示',
            value: 2,
          },
        ],
      },

      // Auto Save
      save: 3,

      // Opacity options
      bgOpacity: [
        { value: 1, label: '不透明' },
        { value: 0.7, label: '30%' },
        { value: 0.5, label: '50%' },
        { value: 0.3, label: '70%' },
        { value: 0, label: '透明' },
      ],

      // Oss File List
      ossFileList: [],
    },
  };
};
