// Use Material Load
import { materialsLoad } from '../../__utils__/context';

// Exports
export default ({ $http, api, qs }) => {
  // Get Params from Query
  const { domain, modelTagvalueId, ['saas-membercode']: memberCode, ['saas-tenantcode']: tenantCode, ['saas-token']: saasToken, ['saas-channelcode']: channelCode } = qs;

  // Exports
  return {
    // PFS Get: modelTagvalueId
    async PFS_GET({ state, dispatch }) {
      // Get modelTagvalueJson
      const { modelTagvalueJson = {} } = await $http(`${domain}${api.pfsGet}`).get({ modelTagvalueId, memberCode, tenantCode, channelCode });

      // Parse Json
      const { version, store, setting } = JSON.parse(modelTagvalueJson);
      console.log('store in pfs get: ', store);

      // Hard Extension
      const extension = {
        dataOssUrl: '',
        fluid: false,
        vertexes: {
          header: [],
          footer: [],
        },
      };

      // Update State
      await dispatch('STATE_UPDATE', { version, store: [store], setting, ...extension });

      // Load Materials
      materialsLoad({ version });

      // Create Page
      // return await dispatch('PAGE_CREATE');
    },

    // PFS Update
    async PFS_UPDATE({ state }) {
      // Set modelTagvalueJson
      const modelTagvalueJson = JSON.stringify({
        version: state.version,
        store: _.cloneDeep(state.store)[0],
        setting: state.setting,
      });

      // Send HTTP
      return await $http(`${domain}${api.pfsSet}`).post({ modelTagvalueId, modelTagvalueJson, memberCode, tenantCode, channelCode });
    },
  };
};
