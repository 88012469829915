<style lang="less" scoped>
.parts {
  position: relative;

  & > div {
    position: absolute;
  }
}
</style>

<template>
  <div class="parts">
    <div v-for="(item, index) in source" :key="index" :style="{ ...css(item.axes) }">
      <slot :item="item" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: [Array],
      default() {
        return [];
      },
    },
  },

  methods: {
    css(axes) {
      return {
        width: `${axes.w}px`,
        height: `${axes.h}px`,
        left: `${axes.x}px`,
        top: `${axes.y}px`,
      };
    },
  },
};
</script>
