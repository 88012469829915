<style lang="less" scoped></style>

<template>
  <div class="fun">
    <component :is="icon" />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: [String],
      default: () => {
        return '';
      },
    },
  },

  data() {
    return {
      // Torance
      rance: 1,
    };
  },

  methods: {},

  computed: {},
};
</script>
