<style lang="less">
.fluid {
  @effect: 0.36s;
  @colour: #e7e8e9;
  @deep: #0081a3;
  @theme: #00a6c9;
  @anchor: #bdbdbd;

  &--container {
    // Reset Height for Fluid
    height: auto !important;
  }

  &--groove {
    position: relative;
    border-radius: 2px;
    border: 1px dashed @colour;

    &--anchor {
      transition: opacity @effect, background-color @effect;

      padding: 0 2px;

      z-index: 99;
      top: 1px;
      bottom: 1px;
      right: 1px;

      position: absolute;

      opacity: 0;

      display: flex;
      align-items: center;

      background-color: @anchor;

      cursor: move;

      // &:hover + .groove .groove--item {
      //   border-color: @theme;
      //   background: @colour;
      // }

      i {
        font-size: 3px;
      }

      &:hover {
        background-color: @theme;
      }
    }

    &--mask {
      // height: 1px;
      // min-height: 20px;
    }

    &.active /* &:hover */ {
      border-color: @deep;
      // background: @colour;

      .fluid--groove--anchor {
        opacity: 1;
      }
    }

    &.hover {
      border-color: @theme;
      // background: @colour;
    }

    &.sortable-chosen {
      border-color: @theme !important;

      .fluid--groove--anchor {
        background-color: @deep;
      }
    }
  }

  &--hover:hover {
    // border-color: @colour;
    // background: #fff;
  }
}
</style>

<template>
  <div class="fluid" :id="id">
    <visual-page v-for="(source, key) in store" :key="source.id" :index="key" v-if="visual.effect">
      <template #default="{ size }">
        <!-- Preview - Some Groove -->
        <div class="fluid--container" v-if="preview">
          <div class="fluid--groove" :class="{ active: original === key }" v-for="(element, index) in source.context" :key="index" :style="{ marginTop: index ? `${setting.flow.gap}px` : 0 }">
            <visual-groove :css="{ minHeight: `${setting.flow.line}px` }" :index="index" :source="element" />
          </div>
        </div>

        <!-- Editor - Some Groove -->
        <dragger v-else class="draggable fluid--container" :group="{ name: 'fluid', put: false }" handle=".fluid--groove--anchor" ghost-class="ghost" animation="360" v-model="source.context" :item-key="getKey">
          <template #item="{ element, index }">
            <div class="fluid--groove" :class="{ hover: originalSync === index, active: original === index /* 'fluid--hover': element.length */ }" :style="{ marginTop: index ? `${setting.flow.gap}px` : 0 }">
              <div class="fluid--groove--anchor">
                <visual-gui-icon mode="shangxiatuodong" />
              </div>
              <visual-groove :css="{ minHeight: `${setting.flow.line}px` }" :index="index" :source="element" />
            </div>
          </template>
        </dragger>
      </template>
    </visual-page>
  </div>
</template>

<script>
export default {
  props: {
    store: {
      type: [Object],
      default() {
        return [];
      },
    },
    id: {
      type: [String],
      default() {
        return '';
      },
    },
  },

  data() {
    return {
      drag: false,
    };
  },

  methods: {
    getKey(item) {
      return item.order || 0;
    },
  },
};
</script>
