<style lang="less" scoped>
:deep(.vdr) {
  cursor: auto;
  transition: all 0.12s;

  &.active {
    cursor: move;
  }

  &:hover {
    background-color: rgba(#00a6c9, 0.125);
  }
}
</style>

<template>
  <vue-drag-resize-motion-subscribe :source="source" :subscribe="{ active: visual.active, grid: one, parent, snapToGrid: setting.gridMinify, smooth: true, anchor: can }" :offset="offset" :unit="unit" :space="space" :clone="clone" @focus-in="onFocusIn" @focus-out="onFocusOut" @dropable="onDropable" @dragstop="onDragStop" @resizestop="onResizeStop" @clicked="onClicked">
    <template #item="{ item }">
      <slot :item="item" />
    </template>
  </vue-drag-resize-motion-subscribe>
</template>

<script>
export default {
  props: {
    source: {
      type: [Array],
      default() {
        return [];
      },
    },

    parent: {
      type: [Object],
      default() {
        return {};
      },
    },

    offset: {
      type: [Object],
      default() {
        return {};
      },
    },

    unit: {
      type: [Object],
      default() {
        return {};
      },
    },

    space: {
      type: [Number, String],
      default() {
        return 0;
      },
    },
  },

  methods: {
    clone(element, { x, y, w, h } = {}) {
      // Merge Coordinate
      element.axes.x = x;
      element.axes.y = y;
      element.axes.w = w;
      element.axes.h = h;

      // Set Component
      const component = _.cloneDeep(element);

      // Create ID of Runtime
      component.id = new Date().getTime() + '';

      // Active for UE
      component.active = false;

      // Init Resource
      component.source = this.resource;

      // Init Repeat Index
      component.option.repeatIndex = 0;

      // Useage
      return component;
    },

    onFocusIn(item) {
      item.active = true;

      this.$store.update('visual', {
        active: item,
      });
    },

    onFocusOut(item) {},

    onDropable(item) {
      // Clean Active
      this.$store.dispatch('visual/CLEAN_ACTIVE_ALL');

      // Set Active
      this.onFocusIn(item);

      // Set History
      this.$store.dispatch('visual/HISTORY_SNAPSHOT');
    },

    onClicked() {},

    onDragStop() {
      // Set History
      this.$store.dispatch('visual/HISTORY_SNAPSHOT');
    },

    onResizeStop() {
      // Set History
      this.$store.dispatch('visual/HISTORY_SNAPSHOT');
    },
  },
};
</script>
