export default ({ qs, html2canvas, jsPDF, sendMessage }) => {
  // Exports
  return {
    /**
     * PDF Render
     * ========== ========== ==========
     */
    async PDF_RENDER({ state }, target) {
      // No Target
      if (!target) {
        // return sender({ stream: null });
        return sendMessage({ mode: 'onPDFStream', stream: null });
      }

      // Get Width and Height from Page
      const { clientWidth, clientHeight } = target;

      // Calculate - Why ?
      const w = (clientWidth * 592.28) / 841.89;
      const h = (clientHeight * 592.28) / 841.89;

      // Step 0. Create PDF
      const pdf = new jsPDF('p', 'pt', 'a4', true);

      // Step 1. Get Canvas from HTML
      html2canvas(target).then(canvas => {
        // Step 2. Get Image from Canvas
        const image = canvas.toDataURL('image/png');

        // Step 3. Add Image into PDF as JEPG
        pdf.addImage(image, 'JPEG', 0, 0, w, h, '', 'FAST');

        // Step 4. Export output String
        const stream = qs.pdf === 'save' ? pdf.save() : pdf.output();

        // Export Stream
        sendMessage({ mode: 'onPDFStream', stream });
      });

      // Endness
      return false;
    },
  };
};
