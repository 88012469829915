<template>
  <div ref="effect">
    <slot />
  </div>
</template>

<script>
/**
 * APIs of `popmotion`
 * ========== ========== ==========
 * exports.Action
 * exports.ValueReaction
 * exports.action
 * exports.calc
 * exports.chain
 * exports.composite
 * exports.crossfade
 * exports.css
 * exports.decay
 * exports.delay
 * exports.easing
 * exports.everyFrame
 * exports.inertia
 * exports.keyframes
 * exports.listen
 * exports.merge
 * exports.mouse
 * exports.multicast
 * exports.multitouch
 * exports.parallel
 * exports.physics
 * exports.pointer
 * exports.schedule
 * exports.spring
 * exports.stagger
 * exports.styler
 * exports.svg
 * exports.timeline
 * exports.transform
 * exports.tween
 * exports.value
 * exports.valueTypes
 * ========== ========== ==========
 */

// Use Popmotion
import { listen, value, styler, pointer } from 'popmotion';

// Export
export default {
  props: {
    container: {
      type: [HTMLDocument, HTMLElement],
      default() {
        return document;
      },
    },

    target: {
      type: [HTMLDocument, HTMLElement],
      default() {
        return document;
      },
    },

    smooth: {
      type: [String, Number],
      default() {
        return 200;
      },
    },
  },

  data() {
    return {
      // Dom Element
      element: null,

      /**
       * true: 占用
       * false: 闲置
       * ========= ========= =========
       */
      occupy: false,
    };
  },

  methods: {
    // Smooth Set
    transform() {
      // Use Transform
      const { transformMap, smooth } = transform;

      // Set
      return transformMap({
        x: smooth(this.smooth),
        y: smooth(this.smooth),
      });
    },

    // Event Set
    event(element) {
      // Use Listen of Down
      listen(this.$refs.effect.parentNode, 'mousedown touchstart').start(e => {
        // Stop Move
        if (this.visualUtils.closest(e.target, '.draggable, .panel, .stop', true)) {
          return;
        }

        // Grabbing Mode
        this.visual.grabbing = true;

        // Remove Active
        this.$store.update('visual', {
          active: null,
        });

        // Set Occupy
        this.occupy = true;

        // Pointer Move
        pointer(this.visual.effect).start(element);

        // Stop Event Default
        e.preventDefault();
      });

      // Stop Listen of Up
      listen(this.container, 'mouseup touchend').start(() => {
        // Grabbing Mode
        this.visual.grabbing = false;

        // Set Occupy
        this.occupy = false;

        // Pointer Stop
        element.stop();
      });
    },
  },

  computed: {
    // Position for Effect Init
    position() {
      // Preset X
      const preset = {
        x: this.setting.size.w ? this.setting.size.w : this.preset.page[this.setting.mode][0],
        y: this.setting.size.h ? this.setting.size.h : this.preset.page[this.setting.mode][1],
      };

      // Set X
      const x = (this.preset.screen.w - this.visualUtils.mm2px(preset.x, 1, 1)) / 2;
      // Set Y
      const y = (this.preset.screen.h - this.visualUtils.mm2px(preset.y, 1, 1)) / 2;

      // Export
      return { x, y };
    },
  },

  mounted() {
    // Stop Use
    // return;
    this.visualUtils.wait().then(() => {
      // Set Element
      this.element = value(this.position, styler(this.$refs.effect).set);

      this.element.subscribe(effect => {
        // Update Effect
        this.$store.update('visual', {
          effect,
        });
      });

      // Trigger Event
      this.event(this.element);
    });
  },
};
</script>
