// Use QS
import { qs } from '../__utils__';

// Get Mock ?
const { mock } = qs();

// Get Env as NODE_ENV
const env = { dev: 'dev', fat: 'fat', prod: 'prod' }[process.env.VUE_APP_ENVIRONMENT];

// Get Protocol
const protocol = location.protocol;

// Base Url of Environment
const yapi = `${protocol}//api.joenix.com`; //${protocol}//yapi.lcops.xyz/mock/913`;

const base = mock
  ? yapi
  : {
      dev: `${protocol}//api.joenix.com`,
      prod: `${protocol}//api.joenix.com`,
    }[env];

// Export API
export default {
  // Store List
  storeList: `${base}/store/list`,
  // Store Load
  storeGet: `${base}/store`,
  // Store Update
  storeUpdate: `${base}/store/save`,
  // Store Delete
  storeDelete: `${base}/store/delete`,
  // Store Copy
  storeCopy: `${base}/store/copy`, // ???

  // Template List
  templateList: `${base}/template/list`,
  // Template Load
  templateGet: `${base}/template/info`,
  // Template Update
  templateUpdate: `${base}/template/upload`,
  // Template Delete
  templateDelete: `${base}/template/delete`,
  // Template Copy
  templateCopy: `${base}/template/copy`,

  // Get Oss File List
  ossList: `${base}/oss/list`,
  // Oss Upload
  ossUpload: `${base}/oss/upload`, // ???
  // Oss Download
  ossDownload: `${base}/oss/down`,
  // Delete Oss File
  ossDelete: `${base}/oss/delete`,
  // Oss Copy
  // ossCopy: `${base}/oss/copy`,

  // Resource Get
  resourceGet: `${yapi}/getResource`,
  // Content Get
  contentGet: `${yapi}/getContent`,

  // PFS Get
  pfsGet: `/web/pfs/pfsmodeltagvalue/getPfsModelTagValue.json`,
  // PFS Set
  pfsSet: `/web/pfs/pfsmodeltagvalue/updatePfsModelTagValueDomain.json`,
};
