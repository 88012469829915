<style lang="less" scoped>
/* Origin for Scroll */
.container-origin {
  width: 100%;
  height: 100%;

  padding: 10vh 10vw;

  /**
   * Scroll None
   * ==========
   */
  overflow: auto;
  scroll-behavior: smooth;

  // Chrome
  &::-webkit-scrollbar {
    display: none;
  }

  // Firefox
  scrollbar-width: none;

  // IE 10+
  -ms-overflow-style: none;
}

.container {
  width: 100%;
  height: 100%;

  transition: transform 0.24s cubic-bezier(0.125, 0.1, 0.125, 1);

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently not supported by any browser */
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <visual-scrolling class="wrapper" v-if="mobox">
    <slot />
  </visual-scrolling>

  <visual-effect class="container" v-else>
    <slot />
  </visual-effect>
</template>

<script>
export default {
  methods: {
    space(adsorbent = {}) {
      return adsorbent.open ? adsorbent.max : adsorbent.min;
    },
  },
};
</script>
