<style lang="less" scoped>
.layout {
  @indent: 8px;
  @effect: 0.24s;
  @color: #fff;
  @theme: #00a6c9;

  overflow: hidden;
  background-color: transparent;

  cursor: pointer;

  .effect(@alpha: 0.825) {
    color: white;
    background-color: rgba(@theme, @alpha);

    .layout--icon {
      opacity: 1;
    }
  }

  &--item {
    padding: 0 @indent;
    transition: background-color @effect;

    &:hover {
      .effect(0.625);
    }

    &.active {
      .effect;

      transition: padding-left @effect;
      padding-left: @indent * 3;
    }
  }

  &--icon,
  &--content,
  svg {
    line-height: 42px;
  }

  &--icon {
    transition: opacity @effect, color @effect;

    width: 10%;
    float: right;
    top: 3px;
    position: relative;
    opacity: 0;

    &:hover {
      color: darken(@theme, 0.5);
    }
  }

  &--content {
    width: 90%;
    transition: color @effect;
  }
}
</style>

<template>
  <dragger class="layout" :group="visual.namespace" animation="360" v-model="(keeper[0] || {}).context" item-key="item">
    <template #item="{ element }">
      <div class="layout--item" :class="{ active: active === element.id }" @click="onFocusIn(element)">
        <MoreOutlined class="layout--icon" />
        <a-space class="layout--content">
          <visual-gui-icon :mode="element.meta.iconfont" />
          <span>{{ element.meta.label }} - {{ intercept(element.id, 6) }}</span>
        </a-space>
      </div>
    </template>
  </dragger>
</template>

<script>
export default {
  data() {
    return {
      drag: false,
    };
  },
  computed: {
    active() {
      return this.visual.active ? this.visual.active.id : null;
    },
  },

  methods: {
    intercept(value, max = 4) {
      const length = value.length - 1;

      return value.substr(length - (max - 1), length);
    },

    onFocusIn(item) {
      // Clean Actives
      this.$store.dispatch('visual/CLEAN_ACTIVE_ALL');

      // Set Active
      item.active = true;

      // Update Active
      this.$store.update('visual', {
        active: item,
      });
    },
  },
};
</script>
