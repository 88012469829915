<template>
  <div />
</template>

<script>
import { bindMessage, sendMessage } from '@skylla/sdk/api';

export default {
  data() {
    return {};
  },

  methods: {
    // Id is from Query
    async copy(id) {
      // Set That as this
      const that = this;

      // Bind Event Message
      await bindMessage({
        // Wait for Ready
        async onCopyReady({ mode, data }) {
          // Set Token First
          window.Authorization = data.token;
          // Get Copy Message
          const store = await that.$store.dispatch('visual/STORE_COPY', { id, ...data });

          // Send Complete
          await sendMessage({ mode: 'onCopyComplete', data: store });
        },
      });
    },
  },

  mounted() {
    // Loop
    this.visualUtils.loop(this.query, async (value, key) => {
      // Command as Methods
      try {
        await this[key](value);
      } catch (e) {
        // console.log('no methods name:', key);
      }
    });
  },
};
</script>
