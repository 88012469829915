export default ({ modern, fluid, mobox, grid, query }) => {
  // Exports
  return {
    // Token as User
    token: null,

    // ID: This ID Create by Back-End or Pick on Query
    id: -1,

    // Runtime Effect
    effect: {
      x: 0,
      y: 0,
    },

    // Preview as Router
    preview: /\/preview/.test(location.pathname),

    // Printer as postMessage
    poster: query.loader === 'poster',

    // Differentiate modern
    modern,
    fluid,
    mobox,
    grid,

    // Grabbing
    grabbing: false,

    // Printer
    printer: false,

    // Runtime Active: Object
    active: null,

    // Runtime Active: Object
    // Runtime Active: Object
    hover: null,

    // Runtime Material Original -- Fluid
    original: -1,

    // Runtime Material Original Hover Sync -- Fluid
    originalSync: -1,

    // Runtime Edit Can: Delete ?
    can: true,

    // Runtime Focus
    focus: null,

    // Runtime Step for History: -1: unload, 0: disabled, 1: running
    step: -1,

    // Namespace
    namespace: 'visual',

    // Version
    version: 'latest',

    // Lock for Repeat
    lock: false,

    /**
     * Store for Database
     * ========== ========== ==========
     * @object { source }
     *  @prop { id }
     *  @prop { env }
     *  @prop { context }
     *    @prop { component }
     *      @prop { id }
     *      @prop { axes }
     *      @prop { meta }
     * ========== ========== ==========
     */
    store: [],

    /**
     * ========== ========== ==========
     * Resource: Presource Setting
     * ========== ========== ==========
     */
    resource: [], // []

    /**
     * ========== ========== ==========
     * Resource: Presource Setting
     * ========== ========== ==========
     */

    /**
     * ========== ========== ==========
     * Content: Precontent Setting
     * ========== ========== ==========
     */
    content: null, // []

    /**
     * ========== ========== ==========
     * Vertexes
     * ========== ========== ==========
     */
    vertexes: {
      // Components in Header
      header: [],

      // Components in Footer
      footer: [],
    },

    // History Cache
    history: {
      max: 20,
      cache: [],
      lock: false,
    },

    // Material Loading
    materials: [],

    // Share Info
    share: {
      // Title Info
      title: '',
      // Description Info
      description: '',
      // Picture
      picture: '',
    },
  };
};
