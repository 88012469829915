<style lang="less" scoped>
.debug {
  position: fixed;
  z-index: 9527;
  left: 0;
  bottom: 0;
  overflow: auto;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

  pre {
    width: 30vw;
    height: 30vh;
    padding: 16px;
    display: block;
  }

  :deep(.ant-space) {
    border-bottom: 1px solid #eee;
  }

  :deep(.ant-input-group-addon),
  :deep(.ant-input) {
    border: none;
  }

  code {
  }
}
</style>

<template>
  <div class="debug">
    <a-space>
      <a-input addon-before="store" v-model:value="subject" />
      <span>
        step: {{ visual.step }}, cache: {{ visual.history.cache.length }}
      </span>
    </a-space>
    <pre><code>{{ text }}</code></pre>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subject: '',
    };
  },
  computed: {
    text() {
      return this.subject ? eval(`this.visual.store${this.subject}`) : this.visual.store;
    },
  },
};
</script>
