<style lang="less" scoped>
.frame-saas {
  border: 1rem solid red;
}
</style>

<template>
  <div class="frame-saas">
    <div>点击按钮直接 Post Message</div>
    <div>真实场景可以，选择之后，点击【确认】按钮再发送</div>
    <a-space direction="vertical">
      <a-button @click="returnTestSource">返回测试数据</a-button>
      <a-button @click="returnGoodsSource">返回商品数据</a-button>
      <a-button @click="returnImageSource">返回图片数据</a-button>
    </a-space>
  </div>
</template>

<script>
export default {
  data() {
    return {
      testSource: {
        a: 1, b: 2
      },
      goodsSource: [
        {
          id: 9527,
          title: '神仙水',
          price: 500
        },
        {
          id: 7086,
          title: 'SK II',
          price: 250
        }
      ],
      imageSource: [
        {
          src: 'http://',
          name: '图片A'
        }
      ],
    }
  },

  methods: {
    returnTestSource() {
      top.postMessage(JSON.stringify({ source: this.testSource }), top);
    },

    returnGoodsSource() {
      top.postMessage(JSON.stringify({ source: this.goodsSource }), top);
    },

    returnImageSource() {
      top.postMessage(JSON.stringify({ source: this.imageSource }), top);
    }
  },

  created() {
    window.addEventListener('message', ({ data }) => {
      console.log('get options in frame page 888:', data)
    }, false)
  }
}
</script>
