<style lang="less" scoped>
.proof {
  & {
    position: relative;
    display: block;
    flex-direction: column;
    cursor: auto;
  }

  &--item {
    flex: 1;
    //border: 1px dashed transparent;
    margin-bottom: 10px;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    width: 100%;

    &.active {
      // 点击时选中的样式
      // border-color: lighten(#00a6c9, 5%);
      // border: 2px solid #2589ff;
      // background-color: #e6fbff;
      // box-shadow: 0 2px 20px 5px rgb(50 59 77 / 30%);
      z-index: 88;
      box-sizing: border-box;
    }

    &.active:after {
      outline: #00A6C9 2px solid;
      //border: 2px solid #00A6C9;
      opacity: .6;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
    }

    &:hover {
      // 经过组件时的样式
      // background: #E6FBFF;
      // border: 1px solid #00A6C9;
    }
    .name {
      position: absolute;
      right: calc(100% + 10px);
      width: 80px;
      min-width: 68px;
      min-height: 24px;
      padding: 4px 7px;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
      box-sizing: border-box;
      position: absolute;
      text-align: center;
      background: #fff;
      font-size: 14px;
      border-radius: 2px;
    }
    .name::after {
      content: ' ';
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-left-color: #fff;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      right: -10px;
    }
    .accessory {
      position: absolute;
      left: 100%;
      top: 0px;
      z-index: 3;
    }
    .block {
      display: block;
    }
    .none {
      display: none;
    }
  }

  &:empty {
    &:hover {
      // background: #e6fbff;
    }
  }
}
</style>

<template>
  <!-- Preview -->
  <div class="proof" :style="{ ...css }" v-if="preview">
    <div class="proof--item" v-for="(element, index) in source" :key="index" :class="{ active: visual.active ? visual.active.id === element.id : false }" :style="{ flex: setCss(element, 'flex', 1), ...setCssPadding(element) }" @click="onClicked(element)">
      <component :is="element.name" :option="element.option" :source="element.source" :vars="element.vars" :axes="element.axes" />
    </div>
  </div>

  <!-- Editor -->
  <dragger v-else :style="{ ...css, ...globalCss}" class="draggable proof" :group="{ name: 'proof' }" ghost-class="ghost" animation="360" v-model="source" item-key="id">
    }
    <template #item="{ element }">
      <div class="proof--item" :class="{ active: visual.active ? visual.active.id === element.id : false }" :style="{ flex: setCss(element, 'flex', 1), ...floorCss}" @click="onClicked(element)">
        <div class="name">
          {{ element.meta.label }}
        </div>
        <visual-accessory :class="visual.active && element.id == visual.active.id ? 'block' : 'none'" class="accessory" :element="element" />
        <component :is="element.name" :option="element.option" :source="element.source" :vars="element.vars" :axes="element.axes" />
      </div>
    </template>
  </dragger>
</template>

<script>
export default {
  created	() {
    this.$store.update("visual", {
      setting: {
        floor: {
          css: {
            marginBottom: "10px"
          }
        },
        global: {
          css: {
            backgroundColor: "#FFFFFF"
          }
        }
      }
    });
  },
  props: {
    index: {
      type: [Number],
      default() {
        return -1;
      },
    },

    source: {
      type: [Array],
      default() {
        return [];
      },
    },

    css: {
      type: [Object],
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      drag: false
    };
  },
  watch: {
    // Vue 无法直接监听 多维数组
    source: {
      handler(value) {
        this.keeper[this.index].context = value;
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    setOptionCss(target) {
      return target.option.css || {};
    },

    setCss(target, property, def) {
      return this.setOptionCss(target)[property] || def;
    },

    setCssPadding(target) {
      const { top, left, right, bottom } = this.setCss(target, 'padding', {});

      console.log(172, top, left, right, bottom)

      return {
        paddingTop: `${top}px`,
        paddingLeft: `${left}px`,
        paddingRight: `${right}px`,
        paddingBottom: `${bottom}px`,
      };
    },

    onFocusIn(item) {
      item.active = true;

      this.$store.update('visual', {
        active: item,
      });
    },

    onClicked(item) {
      // Clean Active
      this.$store.dispatch('visual/CLEAN_ACTIVE_FIRST');

      // Set Active
      this.onFocusIn(item);
    },
  },
  computed: {
    floorCss() {
      return this.setting.floor.css;
    },
    globalCss() {
      return this.setting.global.css;
    }
  }

};
</script>
