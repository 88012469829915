<style lang="less">
.groove {
  & {
    display: flex;
    flex-wrap: wrap;
    cursor: auto;
  }

  &--item {
    flex: 1;
    border: 1px dashed transparent;
    border-radius: 2px;

    cursor: pointer;

    &.active {
      border-color: lighten(#00a6c9, 5%);
      background-color: #e6fbff;
    }

    &:hover {
      // background: #E6FBFF;
      // border: 1px solid #00A6C9;
    }
  }

  &:empty {
    &:hover {
      // background: #e6fbff;
    }
  }
}
</style>

<template>
  <!-- Preview -->
  <div class="groove" :style="{ ...css }" v-if="preview">
    <div class="groove--item" v-for="(element, index) in source" :key="index" :class="{ active: visual.active ? visual.active.id === element.id : false }" :style="{ flex: setCss(element, 'flex', 1), ...setCssPadding(element) }" @click="onClicked(element)">
      <component :is="element.name" :option="element.option" :source="element.source" :vars="element.vars" :axes="element.axes" />
    </div>
  </div>

  <!-- Editor -->
  <dragger v-else :style="{ ...css }" class="draggable groove" :group="{ name: 'groove' }" ghost-class="ghost" animation="360" v-model="source" item-key="id">
    <template #item="{ element }">
      <div class="groove--item" :class="{ active: visual.active ? visual.active.id === element.id : false }" :style="{ flex: setCss(element, 'flex', 1), ...setCssPadding(element) }" @click="onClicked(element)">
        <component :is="element.name" :option="element.option" :source="element.source" :vars="element.vars" :axes="element.axes" />
      </div>
    </template>
  </dragger>
</template>

<script>
export default {
  props: {
    index: {
      type: [Number],
      default() {
        return -1;
      },
    },

    source: {
      type: [Array],
      default() {
        return [];
      },
    },

    css: {
      type: [Object],
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      drag: false,
    };
  },

  watch: {
    // Vue 无法直接监听 多维数组
    source: {
      handler(value) {
        this.first.context[this.index] = value;
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    setOptionCss(target) {
      return target.option.css || {};
    },

    setCss(target, property, def) {
      return this.setOptionCss(target)[property] || def;
    },

    setCssPadding(target) {
      const { top, left, right, bottom } = this.setCss(target, 'padding', {});

      return {
        paddingTop: `${top}px`,
        paddingLeft: `${left}px`,
        paddingRight: `${right}px`,
        paddingBottom: `${bottom}px`,
      };
    },

    onFocusIn(item) {
      item.active = true;

      this.$store.update('visual', {
        active: item,
      });
    },

    onClicked(item) {
      // Clean Active
      this.$store.dispatch('visual/CLEAN_ACTIVE_FIRST');

      // Set Active
      this.onFocusIn(item);
    },
  },
};
</script>
