<style lang="less" scoped>
/**
 * Scrollbar
 * ========== ========== ==========
 */
.scrollbar (@opacity: 1, @space: 8px, @thumb: rgba(#484848, 0.625), @track: transparent) {
  // Vertical First
  overflow-x: hidden;
  overflow-y: auto;

  // 滚动条容器
  &::-webkit-scrollbar {
    // 宽度 -- 竖向滚动条
    width: @space;
    // 高度 -- 竖向滚动条
    height: 0;
  }

  // 滚动条轨道
  &::-webkit-scrollbar-track {
    background-color: @track;
  }

  // 滚动条滑块
  &::-webkit-scrollbar-thumb {
    opacity: @opacity;
    background: @thumb;
    border-radius: (@space / 2) * 1px;
  }
}

// 操控层
.adsorbent {
  @distance: 40px;
  @line: #f0f0f0;

  & {
    width: 100%;
    height: 100%;
    z-index: 100;
    position: relative;

    pointer-events: none;
  }

  & > section {
    position: fixed;
  }

  // Taber
  &--taber {
    background-color: white;
  }

  // Wrapper
  &--wrapper {
    width: 100%;
    height: 100%;

    background-color: white;

    :deep(.ant-tabs-bar) {
      height: 46px;
    }

    :deep(.ant-tabs-tabpane) {
      .scrollbar;

      height: calc(100vh - 123px);
    }
  }

  // Stage
  &--stage {
    .scrollbar;

    width: 100%;
    height: 100%;

    background-color: white;
  }

  // Panel
  .panel {
    cursor: auto !important;
    background: transparent;
  }

  // Full
  .top,
  .left,
  .right,
  .bottom,
  .console {
    pointer-events: none;
  }

  // Distance
  .top {
    top: 0;

    // Special for GUI
    z-index: 1;
    box-shadow: 0 8px 8px #eee;
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }
  .bottom {
    bottom: 0;

    // Special for GUI
    // border-top: 1px solid #eee;
  }
  .console {
  }

  .top,
  .bottom {
    // height: @distance;

    left: 0;
    right: 0;
  }

  .left,
  .right {
    // width: @distance;

    // top: @distance;
    // bottom: @distance;
  }

  // 操作台
  .console {
    // top: @distance;
    // left: @distance;
    // right: @distance;
    // bottom: @distance;

    // Simgle in Console
    .adsorbent--stage {
      background-color: transparent;
    }

    // display: none;
  }

  // Edit Mode
  &.edit {
    pointer-events: none;

    .top,
    .left,
    .right,
    .bottom,
    .console {
      pointer-events: all;
    }
  }

  // Board
  &--board {
    background-color: white;
  }

  // Taber
  :deep(.ant-tabs-nav) {
    width: 100%;

    & > div {
      display: flex;

      .ant-tabs-tab {
        flex: 1;
        text-align: center;
      }
    }
  }

  :deep(.ant-tabs-ink-bar) {
    width: 80px !important;

    position: relative;
    left: 20px;
  }
}
</style>

<template>
  <div class="adsorbent" :class="{ edit }">
    <section v-for="(part, key) in stage" :class="{ panel: true, open: !!part.length, [key]: true }" :style="space[key]">
      <!-- Several -->
      <template v-if="adsorbent[key].tab === true && part.length > 1">
        <a-tabs class="adsorbent--wrapper" :tab-bar-gutter="0">
          <a-tab-pane v-for="(item, index) in stage[key]" :key="index" :tab="dict[item.name]">
            <div :style="full(key)">
              <slot :name="key" :item="item" />
            </div>
          </a-tab-pane>
          <!--
          <template #renderTabBar="{ DefaultTabBar, ...props }">
            <component :is="DefaultTabBar" v-bind="props" />
          </template>
          -->
        </a-tabs>
      </template>

      <!-- Single -->
      <template v-else>
        <dragger class="adsorbent--stage" group="stage" handle=".trigger" animation="360" v-model="stage[key]" :item-key="key">
          <template #item="{ element }">
            <div :style="full(key)">
              <slot :name="key" :item="element" />
            </div>
          </template>
        </dragger>
      </template>
    </section>
  </div>
</template>

<script>
// Use SDK Component
import { dragger } from '@skylla/sdk/vue';

export default {
  components: {
    dragger,
  },

  props: {
    edit: {
      type: [Boolean],
      default() {
        return true;
      },
    },
  },

  data() {
    return {
      dict: {
        material: '组件库',
        layer: '图层',
        floor: '楼层',
      },
    };
  },

  computed: {
    stage() {
      return this.visual.interface.stage.distance;
    },

    adsorbent() {
      return this.visual.interface.stage.adsorbent;
    },

    space() {
      // Set Top
      const top = {
        height: this.visualUtils.px(this.adsorbent.top.open ? this.adsorbent.top.max : this.adsorbent.top.min),
      };

      // Set Bottom
      const bottom = {
        height: this.visualUtils.px(this.adsorbent.bottom.open ? this.adsorbent.bottom.max : this.adsorbent.bottom.min),
      };

      // Set Left
      const left = {
        width: this.visualUtils.px(this.adsorbent.left.open ? this.adsorbent.left.max : this.adsorbent.left.min),
        top: top.height,
        bottom: bottom.height,
      };

      // Set Right
      const right = {
        width: this.visualUtils.px(this.adsorbent.right.open ? this.adsorbent.right.max : this.adsorbent.right.min),
        top: top.height,
        bottom: bottom.height,
      };

      // Set Console
      const console = {
        top: top.height,
        // bottom: bottom.height,
        left: left.width,
        // right: right.width,
      };

      return { top, bottom, left, right, console };
    },
  },

  methods: {
    // For GUI
    full(dir) {
      return {
        width: ['left', 'right'].includes(dir) ? '100%' : 'auto',
        height: ['top', 'bottom'].includes(dir) ? '100%' : 'auto',
      };
    },
  },
};
</script>
