<style lang="less" scoped>
.visual-editor {
  & {
    width: 100vw;
    height: 100vh;

    color: #363636;
    background-color: #f7f8f9;

    // Prevent Shake
    overflow: hidden;

    cursor: grab;
  }

  &.grab {
    cursor: grabbing;
  }
}
</style>

<template>
  <!-- 可视化平台 -->
  <div class="visual-editor" :class="{ grab: visual.grabbing }">
    <!-- 容器层 -->
    <visual-container>
      <visual-simulator :store="visual.store" />
    </visual-container>

    <!-- 操控层 -->
    <visual-adsorbent :edit="true" :drag="false">
      <template #top="{ item }">
        <component :is="`visual-gui-${item.name}`" :slot="item.name">
          <slot :name="item.name" :item="item" />
        </component>
      </template>
      <template #left="{ item }">
        <component :is="`visual-gui-${item.name}`" :slot="item.name">
          <slot :name="item.name" />
        </component>
      </template>
      <template #right="{ item }">
        <component :is="`visual-gui-${item.name}`" :slot="item.name">
          <slot :name="item.name" />
        </component>
      </template>
      <template #bottom="{ item }">
        <component :is="`visual-gui-${item.name}`" :slot="item.name">
          <slot :name="item.name" />
        </component>
      </template>
      <template #console="{ item }">
        <component :is="`visual-gui-${item.name}`" :slot="item.name">
          <slot :name="item.name" />
        </component>
      </template>
    </visual-adsorbent>
  </div>
</template>

<script>
import { bindMessage, sendMessage, print } from '@skylla/sdk/api';

export default {
  data() {
    return {};
  },

  methods: {
    // Time Round
    async round(callback) {
      // Take Round
      this.visualUtils.round(100, async () => {
        // No Finish No Endness
        if (!this.finish) {
          return;
        }

        // Handler
        await callback(this);

        // Break;
        return true;
      });
    },

    // Render
    async render(data = {}) {
      // Stylesheet
      await this.visualUtils.stylesheet(data);

      // Init
      await this.$store.dispatch('visual/STORE_INIT');

      // Get Data
      await this.$store.dispatch('visual/DATA_RENDER', { context: this, data });

      // Get Params from Data for Special Useage
      const { token, merge } = data;

      // Special Title from Parent
      if (merge) {
        this.$store.update('visual', {
          ...merge,
          token,
        });
      }

      // Stop
      return;
    },
  },

  async created() {
    // Set That as this
    const that = this;

    // Bind Message as Poster
    if (this.poster) {
      // Bind Event Message
      return await bindMessage({
        // 编辑视图加载完成后，注入数据
        async onVisualWork({ mode, data }) {
          // Set Token First
          window.Authorization = data.token;
          // Set User Id
          window.userId = data.tenantId_officeId_userId;
          // Render
          await that.render(data);

          // Send Complete
          await sendMessage({ mode: 'onVisualComplete', data });
        },

        // 点击 <预览> 按钮，发送 <onPrintReadyCross> 后，等待 数据 注入
        async onPrintWorkCross({ mode, data }) {
          // Set Printer Loading On
          that.$store.update('visual', { printer: true });

          // Get Resource
          const { resource } = that.visual;

          // Get Content
          const { content } = data;

          // Query
          if (!that.query.id) {
            that.query.id = that.visual.id;
          }

          // Do Print
          print({ host: location.origin, query: { ...that.query } }, { resource, content });
        },
      });
    }
  },

  async mounted() {
    // No Poster
    if (this.poster) {
      // Send Ready
      await sendMessage({ mode: 'onVisualReady' });

      // Stop
      return;
    }

    // Init
    // await this.$store.dispatch('visual/STORE_INIT');
    await this.$store.dispatch('visual/PFS_GET');

    // Get Resource with HTTP
    await this.$store.dispatch('visual/RESOURCE_SYNC');
  },
};
</script>
