<template>
  <!-- 可视化平台 -->
  <div>
    <!-- 调试 -->
    <visual-debug v-show="debug" />

    <!-- 配置 -->
    <visual-control v-model:visible="visual.interface.control.visible" />

    <!-- 键盘 -->
    <visual-keyboard @emit="keyboardTrigger" />
  </div>
</template>

<script>
import { bindMessage } from '@skylla/sdk/api';

export default {
  name: 'visual',

  data() {
    return {
      debug: false,
    };
  },

  watch: {
    // Watch Store in Visual for History Step
    'visual.store': {
      handler(store) {
        // Never Used More
        // this.$store.dispatch('visual/HISTORY_SAVE');
      },

      deep: true,
    },
  },

  methods: {
    keyboardTrigger({ altKey, keyCode }) {
      // No Alt (Option)
      if (!altKey) {
        return;
      }

      if (keyCode === 68) {
        this.debug = !this.debug;
      }
    },
  },
};
</script>
