<style lang="less" scoped></style>

<template>
  <div />
</template>

<script>
export default {
  props: {},

  methods: {
    // Option + W
    async 'key.87'() {
      this.$store.update('visual', {
        interface: {
          control: {
            visible: !this.visual.interface.control.visible,
          },
        },
      });
    },

    // Option + D - Debugger
    async 'key.68'() {
      // this.debug = !this.debug;
    },

    async press(event) {
      // No Can
      if (!this.can) {
        return;
      }

      // Emit Trigger
      this.$emit('emit', event);

      // Get Alt and Code
      const { altKey, keyCode } = event;

      // Remove Component
      if ([8, 46].includes(keyCode) && !this.mobox) {
        await this.$store.dispatch('visual/COMPONENT_REMOVE', this.visual.active);
      }

      // No Alt (Option)
      if (!altKey) {
        return;
      }

      // Get Handler
      const handler = this[`key.${keyCode}`];

      // Trigger
      if (handler) {
        await handler();
      }

      // e.preventDefault();
      return false;
    },

    async mouse(e) {
      // Get Element
      const element = this.visualUtils.closest(e.target, '.panel, .stop', true);

      // Update Edit Can
      this.$store.update('visual', {
        can: !element,
      });
    },

    async wheel(e) {
      // Stop Event
      e.stopPropagation();

      // Get Element
      const element = this.visualUtils.closest(e.target, '.adsorbent, .stop', true);

      if (element) {
        return;
      }

      // Get Target
      const target = document.querySelector('.container');

      const effect = {
        x: this.visual.effect.x + e.deltaX / 2,
        y: this.visual.effect.y + e.deltaY / 2,
      };

      this.$store.update('visual', {
        effect,
      });

      if (target) {
        target.style.transform = `translateX(${effect.x}px) translateY(${effect.y}px) translateZ(0px)`;
      }

      e.stopPropagation();

      return false;
    },

    async over(e) {
      window.focus();
    },
  },

  async mounted() {
    // Document Mouse Listener
    document.addEventListener('click', this.mouse);

    // Document Drop Listener
    document.addEventListener('drop', this.mouse);

    // Document Press Listener
    document.addEventListener('keyup', this.press);

    // Document Scroll Listener
    document.addEventListener('mousewheel', this.wheel);

    // Document mouseover Listener
    document.addEventListener('mouseover', this.over);
  },
};
</script>
