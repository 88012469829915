// Use Pre in SDK
import { page } from '@skylla/sdk/pre';

// Use Apis
import api from '../__api__';

// Use Utils
import * as utils from '../__utils__';

// Use States
import states from './state';

// Use Actions
import action from './action';

// Mock
import mock from './mock.json';

// Register Store in Scaff
export default ({ $http, $api }) => {
  // Get Screen
  const screen = window.document.body;

  // Get Query
  const query = utils.qs();

  // Default  Mobox
  const modern = query.mode || 'mobox';

  // Quick Set Name of Modern

  const fluid = modern === `fluid`;
  const mobox = modern === `mobox`;
  const grid = modern === `grid`;

  // Set State
  const state = states({ screen, query, modern, fluid, mobox, grid });

  // Set Mutations
  const mutations = {};

  // Set Actions
  const actions = action({ $http, api, mock, ...utils });

  // Export for Use
  return { state, mutations, actions };
};
