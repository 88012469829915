// Use Material Load
import { materialsLoad } from '../../__utils__/context';

// Exports
export default ({ $http, api, qs, mock, loop, wait, sendMessage }) => {
  // Exports
  return {
    /**
     * Store Clean
     * ========== ========== ==========
     */
    async STORE_CLEAN({ state }, alter = {}) {
      // Cloner
      const cloner = _.cloneDeep(state.store);

      // Get First
      const first = cloner[0];

      // Clean Source Every Store
      loop(first.context, store => Object.assign(store, alter));

      // Export Cloner
      return cloner;
    },

    /**
     * Store Storage Control -- Set
     * ========== ========== ==========
     */
    async STORE_STORAGE_SET({ state }, { namespace = 'visual', data = {} }) {
      // Set as Storage
      localStorage.setItem(namespace, JSON.stringify(data));

      // Success
      return true;
    },

    /**
     * Store Storage Control -- Get
     * ========== ========== ==========
     */
    async STORE_STORAGE_GET({ dispatch }, { namespace = 'visual' }) {
      // Get Content
      const content = localStorage.getItem(namespace) || {};

      // Get as Storage
      return await dispatch('STORE_PARSE', content);
    },

    /**
     * Store Save as Storage
     * ========== ========== ==========
     */
    async STORE_SAVE({ dispatch }, namespace) {
      // Get Properties from State, `dataOssUrl` for `Exports`
      const { version, fluid, store, vertexes, setting, dataOssUrl } = state;

      // Use Storage Set
      return await dispatch('STORE_STORAGE_SET', { namespace, data: { version, fluid, store, vertexes, setting, dataOssUrl } });
    },

    /**
     * Store Load as Storage
     * ========== ========== ==========
     */
    async STORE_LOAD({ state, dispatch }, namespace) {
      // Get Result from Storage
      const { version, fluid, store, vertexes, setting } = await dispatch('STORE_STORAGE_GET', { namespace });

      // Remove Id Fuck On
      delete store.id;

      // Success
      if (store) {
        await dispatch('STORE_INIT');

        return {
          // Remove Active First
          active: null,
          // Version
          version,
          // Mode Fluid
          fluid,
          // Update Store
          store,
          // Update Vertexes
          vertexes,
          // Update Setting
          setting,
        };
      }
    },

    /**
     * Store Parse
     * ========== ========== ==========
     */
    async STORE_PARSE({ dispatch }, content) {
      // Set Context
      let context = {};

      // Use JSON.parse as String
      try {
        context = JSON.parse(content);
      } catch (e) {
        // Normal
        context = content;
      }

      // Recursion Parse
      if (typeof context.content === 'string') {
        context.content = await dispatch('STORE_PARSE', context.content);
      }

      // Exports
      return { ...context };
    },

    /**
     * Store Download
     * ========== ========== ==========
     */
    async STORE_DOWNLOAD({ dispatch }, content) {
      // Json of Content
      return await dispatch('STORE_PARSE', content);
    },

    // Store Check in List
    async STORE_CHECK({ dispatch, state }, params = {}) {
      // Get List
      const list = await dispatch('STORE_LIST');

      // Set Group
      const group = Object.values(list).map(({ id }) => id);

      // In or Not
      return group.includes(state.id);
    },

    /**
     * Store Init
     * ========== ========== ==========
     */
    async STORE_INIT({ dispatch, state }, params = {}) {
      // Check Id on QS
      if (qs.id) {
        // Sync ID
        state.id = qs.id;

        // Lock First
        await dispatch('STATE_UPDATE', {
          history: {
            lock: true,
          },
        });

        // Get Store
        const store = await dispatch('STORE_GET', { ...qs, ...params });

        // Update Store
        await dispatch('STATE_UPDATE', store);

        // unLock after 100ms
        await wait(1000);

        // unLock
        return await dispatch('STATE_UPDATE', {
          history: {
            lock: false,
          },
        });
      }

      // Create with Blank
      return await dispatch('PAGE_CREATE');
    },

    /**
     * Store List Get
     * ========== ========== ==========
     */
    async STORE_LIST({}, params = {}) {
      return await $http(api.storeList).post({ ...qs, ...params });
    },

    /**
     * Get Store
     * ========== ========== ==========
     */
    async STORE_GET({ state, dispatch }, params = {}) {
      // Data
      const data = { ...qs, ...params };

      // Set Title for Out Swap
      var title;

      // Set Content
      var content;

      // Get Content from Storage in Preview Mode
      if (state.preview) {
        var { content, dataOssUrl } = await dispatch('STORE_STORAGE_GET', { namespace: data.id });
      }
      // Get Content from Request If no Content
      if (!content) {
        var { content, dataOssUrl, title } = await $http(api.storeGet).post(data);
      }
      // Hard Code for Content Parse -- For Stupid Title Set
      const parser = await dispatch('STORE_PARSE', content);

      // Set Title Into Parser
      if (title) {
        parser.setting.title = title;
      }

      // Load Materials
      materialsLoad({ version: parser.version });

      // Context Parse
      return {
        dataOssUrl,
        ...parser,
      };
    },

    /**
     * Update Store
     * ========== ========== ==========
     */
    async STORE_UPDATE({ state, dispatch }, params = {}) {
      // Lock
      if (state.lock) {
        // return {
        //   lock: true,
        // };
      }

      // Clean Store as source
      const cloner = await dispatch('STORE_CLEAN', { source: {} });

      // Data
      const data = {
        // Set Another Title for Out Swap
        title: state.setting.title,

        // Set Content for Update
        content: JSON.stringify({
          version: state.version,
          fluid: state.fluid,
          store: cloner, // tate.store,
          vertexes: state.vertexes,
          setting: state.setting,
          // interface: state.interface,
        }),

        // Set ID
        id: state.id, //!~state.id ? qs.id || null : state.id,

        //id: !~state.id ? qs.id || null : state.id,
      };

      // Set to Storage
      if (data.id) {
        // 先注释掉，parse 解析 数据有错误
        await dispatch('STORE_STORAGE_SET', { namespace: data.id, data });
      }

      // Response
      const { id, dataOssUrl } = await $http(api.storeUpdate).post({ /* id: state.id, */ ...data, ...qs, ...params });

      // Has Id - dataOssUrl is Useful for SAAS
      if (id) {
        await sendMessage({ mode: 'onStoreUpdate', data: { id, dataOssUrl, title: state.setting.title } });
      }

      // Data as ID
      return {
        id,
        // lock: true,
      };
    },

    /**
     * Copy Store
     * ========== ========== ==========
     */
    async STORE_COPY({ state }, params = {}) {
      return await $http(api.storeCopy).post({ ...qs, ...params });
    },
  };
};
