<style lang="less" scoped>
.setting {
  padding-top: 10px;

  :deep(.ant-radio-group) {
    display: flex;

    label {
      flex: 1;
      text-align: center;
    }
  }

  :deep(.ant-input-suffix) {
    color: rgba(0, 0, 0, 0.25);
  }

  .suffix {
    border: 1px solid #eee;
    border-left: none;
    background-color: #f8f8f8;
  }

  .item-title {
    margin: 15px 0 10px 0;
  }

  .item-title-first {
    margin: 5px 0 10px 0;
  }

  [class^='input-number'] {
    position: relative;

    &:after {
      top: 0;
      right: 0;
      position: absolute;

      color: rgba(0, 0, 0, 0.25);

      line-height: 30px;
      padding-right: 4px;
    }
  }

  .input-number-bg-x {
    &:after {
      content: '水平';
    }
  }

  .input-number-bg-y {
    &:after {
      content: '垂直';
    }
  }

  .input-number-top {
    &:after {
      content: '上';
    }
  }

  .input-number-bottom {
    &:after {
      content: '下';
    }
  }

  .input-number-left {
    &:after {
      content: '左';
    }
  }

  .input-number-right {
    &:after {
      content: '右';
    }
  }

  .input-number-size-w {
    &:after {
      content: '宽';
    }
  }

  .input-number-size-h {
    &:after {
      content: '高';
    }
  }

  // Flow
  .input-number-gap {
    &:after {
      content: '楼层间距';
    }
  }

  .input-number-line {
    &:after {
      content: '楼层最小高度';
    }
  }

  .input-number-header-space,
  .input-number-footer-space {
    margin-top: 8px;
  }

  .input-number-header-space {
    &:after {
      content: '距离顶部的间距';
    }
  }

  .input-number-footer-space {
    &:after {
      content: '距离顶部的间距';
    }
  }

  .slider-wrapper {
    padding-left: 24px;

    .label {
      position: absolute;
      left: 0;
      top: 13px;
      font-size: 14px;
      line-height: 1;
    }
  }
}
</style>

<template>
  <visual-gui-pack class="setting" :namespace="namespace" v-if="!visual.active">
    <template v-if="mobox">
      <!-- <template #header> 全局设置 </template> -->

      <!-- Flow Set in Fluid Mode -->
      <!-- <visual-gui-part title="楼层设置" :key="namespace[5]">
        <visual-gui-item title="">
          <a-input-number class="input-number-gap" v-model:value="setting.flow.gap" :min="1" :max="100" />
        </visual-gui-item>
        <visual-gui-item title="">
          <a-input-number class="input-number-line" v-model:value="setting.flow.line" :min="1" :max="100" />
        </visual-gui-item>
      </visual-gui-part> -->
      <visual-gui-part title="辅助线设置" :key="namespace[0]">
        <visual-gui-item>
          <a-checkbox v-model:checked="setting.gridAuxiliary">辅助线</a-checkbox>
        </visual-gui-item>
      </visual-gui-part>

      <visual-gui-part title="机型设置" :key="namespace[1]">
        <visual-gui-item class="item-title" title="机型尺寸(px)">
          <a-row type="flex" :gutter="[8, 8]">
            <a-col :span="24">
              <a-select v-model:value="setting.mode" :options="phoneOptions"/>
            </a-col>
            <a-col :span="12" v-if="!mobox">
              <a-input-number class="input-number-size-w" v-model:value="setting.size.w" :min="1" :max="10000"/>
            </a-col>
            <a-col :span="12" v-if="!mobox">
              <a-input-number class="input-number-size-h" v-model:value="setting.size.h" :min="1" :max="10000"/>
            </a-col>
          </a-row>
        </visual-gui-item>
      </visual-gui-part>

      <visual-gui-part title="背景设置" :key="namespace[1]">
        <visual-gui-item>
          <pickerColor
              @setColor="setGlobalColor"
              reset="true"
              :dColor="'#FFFFFF'"
          />
        </visual-gui-item>
      </visual-gui-part>
      <visual-gui-part title="楼层设置" :key="namespace[6]">
        <visual-gui-item title="楼层间隔">
          <a-input-number v-model:value="watchMargin" @change="changeFloorMargin" :min="0"/>
        </visual-gui-item>
      </visual-gui-part>
    </template>

    <template v-else>
      <visual-gui-part title="自动保存文稿" :key="namespace[4]">
        <a-form-item class="input-number-auto-wrap">
          <a-select v-model:value="preset.save" :options="saveSetting"/>
        </a-form-item>
      </visual-gui-part>
      <visual-gui-part title="套打背景图" :key="namespace[3]" v-if="grid">
        <a-row :gutter="8">
          <a-col :span="24">
            <a-form-item>
              <select-upload-image></select-upload-image>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="8">
          <a-col :span="24">
            <a-form-item>
              <a-select v-model:value="setting.backgroundCss.opacity" :options="visual.preset.bgOpacity"></a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <!--
            <a-row :gutter="8">
              <a-col :span="12">
                <a-form-item>
                  <a-input-number class="input-number-bg-x" v-model:value="setting.backgroundCss.x" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item>
                  <a-input-number class="input-number-bg-y" v-model:value="setting.backgroundCss.y" />
                </a-form-item>
              </a-col>
            </a-row>
            -->
      </visual-gui-part>

      <!-- No Grid in Fluid Mode -->
      <visual-gui-part title="栅格设置" :key="namespace[2]" v-if="grid">
        <visual-gui-item title="">
          <a-checkbox v-model:checked="setting.gridAuxiliary">辅助线</a-checkbox>
        </visual-gui-item>

        <visual-gui-item title="">
          <a-checkbox v-model:checked="setting.gridMinify">启用栅格</a-checkbox>
        </visual-gui-item>

        <!--
            <visual-gui-item title="" v-if="setting.gridMinify">
              <a-checkbox v-model:checked="setting.gridVisible"
                >显示栅格辅助线</a-checkbox
              >
            </visual-gui-item>
            -->

        <!--
            <visual-gui-item title="X轴" v-if="setting.gridMinify">
              <a-slider v-model:value="setting.grid.x" :min="1" :max="100" />
            </visual-gui-item>
            <visual-gui-item title="Y轴" v-if="setting.gridMinify">
              <a-slider v-model:value="setting.grid.y" :min="1" :max="100" />
            </visual-gui-item>
            -->

        <a-form-item v-if="setting.gridMinify">
          <div class="slider-wrapper">
            <span class="label">X轴</span>
            <a-slider v-model:value="setting.grid.x" :min="1" :max="100" :tipFormatter="(value) => `${value}格`"/>
          </div>
        </a-form-item>
        <a-form-item v-if="setting.gridMinify">
          <div class="slider-wrapper">
            <span class="label">Y轴</span>
            <a-slider v-model:value="setting.grid.y" :min="1" :max="100" :tipFormatter="(value) => `${value}格`"/>
          </div>
        </a-form-item>
      </visual-gui-part>

      <visual-gui-part title="页面样式" :key="namespace[0]">
        <!--
            <visual-gui-item title="背景颜色">
              <a-input v-model:value="setting.background" />
            </visual-gui-item>
            -->

        <visual-gui-item class="item-title item-title-first" title="纸张方向">
          <a-radio-group v-model:value="setting.orientation">
            <a-radio-button :value="false">纵向</a-radio-button>
            <a-radio-button :value="true">横向</a-radio-button>
          </a-radio-group>
        </visual-gui-item>

        <visual-gui-item class="item-title" title="纸张大小(mm)">
          <a-row type="flex" :gutter="[8, 8]">
            <a-col :span="24">
              <a-select v-model:value="setting.mode" :options="pageOptions"/>
            </a-col>
            <a-col :span="12">
              <a-input-number class="input-number-size-w" v-model:value="setting.size.w" :min="1" :max="10000"/>
            </a-col>
            <a-col :span="12">
              <a-input-number class="input-number-size-h" v-model:value="setting.size.h" :min="1" :max="10000"/>
            </a-col>
          </a-row>
        </visual-gui-item>
        <div class="item-title">页边距(px)</div>
        <a-row :gutter="8">
          <a-col :span="12">
            <a-form-item>
              <a-input-number class="input-number-top" v-model:value="setting.padding.top" :min="1" :max="100"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
              <a-input-number class="input-number-bottom" v-model:value="setting.padding.bottom" :min="1" :max="100"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="8">
          <a-col :span="12">
            <a-form-item>
              <a-input-number class="input-number-left" v-model:value="setting.padding.left" :min="1" :max="100"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
              <a-input-number class="input-number-right" v-model:value="setting.padding.right" :min="1" :max="100"/>
            </a-form-item>
          </a-col>
        </a-row>
      </visual-gui-part>

      <!-- Flow Set in Fluid Mode -->
      <visual-gui-part title="楼层设置" :key="namespace[5]" v-if="fluid">
        <visual-gui-item title="">
          <a-input-number class="input-number-gap" v-model:value="setting.flow.gap" :min="1" :max="100"/>
        </visual-gui-item>
        <visual-gui-item title="">
          <a-input-number class="input-number-line" v-model:value="setting.flow.line" :min="1" :max="100"/>
        </visual-gui-item>
      </visual-gui-part>

      <visual-gui-part title="页眉与页脚" :key="namespace[1]" v-if="!fluid">
        <visual-gui-item class="item-title item-title-first" title="页眉">
          <a-select v-model:value="vertexStatus.header" :options="visual.preset.vertex.header"/>

          <!--
              <a-space style="margin-top: 16px">
                <a-input-number :min="1" :max="vertexHeaderMax" v-model:value="vertex.header.space" />
                <span>距离顶部的间距</span>
              </a-space>
              -->

          <a-input-number class="input-number-header-space" :min="1" :max="vertexHeaderMax"
                          v-model:value="vertex.header.space"/>
        </visual-gui-item>
        <visual-gui-item class="item-title" title="页脚">
          <a-select v-model:value="vertexStatus.footer" :options="visual.preset.vertex.footer"/>

          <!--
              <a-space style="margin-top: 16px">
                <a-input-number :min="1" :max="vertexFooterMax" v-model:value="vertex.footer.space" />
                <span>距离底部的间距</span>
              </a-space>
              -->

          <a-input-number class="input-number-footer-space" :min="1" :max="vertexFooterMax"
                          v-model:value="vertex.footer.space"/>
        </visual-gui-item>

        <visual-gui-item title="页码" v-if="false">
          <a-checkbox v-model:checked="setting.pageNumber">是否显示页码</a-checkbox>
        </visual-gui-item>
      </visual-gui-part>

      <visual-gui-part title="自动保存文稿" :key="namespace[4]">
        <a-form-item class="input-number-auto-wrap">
          <a-select v-model:value="preset.save" :options="saveSetting"/>
        </a-form-item>
      </visual-gui-part>
    </template>
  </visual-gui-pack>
</template>

<script>
import selectUploadImage from '../components/select-upload-image';
import pickerColor from "../components/picker-color/index";

export default {
  components: {
    selectUploadImage,
    pickerColor
  },
  data() {
    return {
      // Cache Save Grid Set
      gridMinifyCache: null,
      backgroundPositionOptions: [
        {value: 'center', label: '居中'},
        {value: 'top', label: '上'},
        {value: 'right', label: '右'},
        {value: 'bottom', label: '下 '},
        {value: 'left', label: '左'},
      ],
      backgroundSizeOptions: [
        {value: 'cover', label: '覆盖'},
        {value: 'contain', label: '包含'},
        {value: 'auto', label: '自动'},
      ],
      saveSetting: [
        {
          label: '不启用自动备份',
          value: 0,
        },
        {
          label: '3分钟',
          value: 3,
        },
        {
          label: '5分钟',
          value: 5,
        },
        {
          label: '10分钟',
          value: 10,
        },
        {
          label: '20分钟',
          value: 20,
        },
        {
          label: '30分钟',
          value: 30,
        },
      ],
      watchMargin: ""
    };
  },

  watch: {
    'preset.save': {
      immediate: true,
      handler(value) {
        return;

        //暂时停用

        // CLear First
        clearInterval(this.timeout);

        // No Value
        if (!value) return;

        // Interval
        this.timeout = setInterval(() => this.save(), value * 60 * 1000);
      },
    },

    'vertexStatus.header'(value) {
      this.computedVertex(value, 'header');
    },

    'vertexStatus.footer'(value) {
      this.computedVertex(value, 'footer');
    },

    'vertex.header.space'() {
      this.computedVertexMain();
    },

    'vertex.footer.space'() {
      this.computedVertexMain();
    },

    'setting.grid.y'() {
      this.computedVertexMain();
    },

    'setting.gridMinify'(value) {
      if (value === false) {
        // Save Temp Variable
        this.setting.gridMinifyCache = this.setting.one;
      }

      // Get One
      const one = value === false ? this.setting.mini : this.setting.gridMinifyCache || this.setting.one;

      // Update Grid Set
      this.$store.update('visual', {
        setting: {
          gridVisible: value,
          one,
        },
      });
    },

    'setting.floor.css.marginBottom'() {
      const str = this.setting.floor.css.marginBottom;
      this.watchMargin = str.slice(0, str.length - 2);
    },
  },

  computed: {
    namespace() {
      return ['page', 'vertex', 'fence', 'assist', 'saveSet', 'fluid', 'bg'];
    },

    pageOptions() {
      const result = [];

      this.visualUtils.each(this.preset.page, (value, key) => {
        result.push({
          label: key === 'custom' ? '自定义' : key,
          value: key,
        });
      });

      return result;
    },

    phoneOptions() {
      const result = [];

      this.visualUtils.each(this.preset.mobile, (value, key) => {
        result.push({
          label: key === 'custom' ? '自定义' : key,
          value: key,
        });
      });

      return result;
    },

    vertexHeaderMax() {
      return this.vertexMain.space + this.vertexHeader.space - 2;
    },

    vertexFooterMax() {
      return this.vertexMain.space + this.vertexFooter.space - 2;
    },
  },

  methods: {
    visualUpdate(param = {}) {
      this.$store.update('visual', {
        setting: {
          vertex: {
            ...param,
          },
        },
      });
    },

    computedMainSpace() {
      // Get Space
      const space = {
        header: this.vertexHeader.visible === false ? 0 : this.vertexHeader.space,
        footer: this.vertexFooter.visible === false ? 0 : this.vertexFooter.space,
      };

      return this.setting.grid.y - (space.header + space.footer);
    },

    computedVertex(value, mode) {
      const visible = value !== 0;
      const every = value === 2;

      this.visualUpdate({
        [mode]: {visible, every},
      });

      this.computedVertexMain();
    },

    computedVertexMain() {
      this.visualUpdate({
        main: {
          space: this.computedMainSpace(),
        },
      });
    },

    // 保存草稿
    async save() {
      const success = await this.$store.dispatch('visual/STORE_SAVE');

      if (success) {
        this.$message.success('草稿保存成功');
      }
    },

    setGlobalColor(color) {
      this.$store.update('visual', {
        setting: {
          global: {
            css: {
              backgroundColor: color
            }
          }
        }
      })
    },

    changeFloorMargin(val) {
      this.$store.update('visual', {
        setting: {
          floor: {
            css: {
              marginBottom: val + 'px'
            }
          }
        }
      })
    }
  },

  mounted() {
    this.computedVertex(0, 'header');
    this.computedVertex(0, 'footer');
  },
};
</script>
