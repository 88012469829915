<style lang="less" scoped>
.material {
  &-fluid {
    display: flex;
    flex-flow: wrap;

    .material-item {
      flex: 0 0 50%;
    }
  }

  &-mobox {
    display: flex;
    flex-flow: wrap;

    .material-item {
      flex: 0 0 50%;
    }
  }

  &-item {
    padding: 24px 8px 16px;

    transition: all 0.24s;
    cursor: grab;

    span {
      line-height: 32px;
    }

    &:hover {
      color: #00a6c9;
      background-color: #f7f8f9;
    }

    &:active {
      cursor: grabbing;
    }
  }

  &-icon {
    font-size: 32px;
  }
}
</style>

<style lang="less">
// in Console
.console {
  .adsorbent--stage {
    & > div[data-draggable] {
      top: 20px;
      left: 20px;
      position: absolute;
    }
  }
  .material {
    width: 110px;
    background: #ffffff;
    border: 1px solid #e4e5e7;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    color: #3e3e3c;

    &-head {
      padding: 6px 0 4px 10px;
      line-height: 18px;
      font-family: PingFangSC-Semibold;
      font-size: 13px;
      color: #3e3e3c;
      letter-spacing: 0;
    }

    &-item {
      display: flex;
      justify-content: flex-start !important;

      padding: 7px 8px !important;
      flex-direction: row !important;

      cursor: pointer;

      &-disabled {
        cursor: not-allowed;
        color: #ccc;

        i::before {
          color: #ccc;
        }
      }

      &-icon {
        font-size: 16px;
        width: 36px;
        height: 36px;
        line-height: 36px;
        color: #000;
      }

      span {
        line-height: 18px;
      }

      &:hover {
        background-color: #f5f6f7;
      }
    }
  }
}
</style>

<template>
  <div class="material">
    <!-- ******** MOBOX ******** -->
    <dragger v-if="mobox" class="material-mobox" :group="{ name: 'proof', pull: 'clone', put: false }" :sort="false" animation="360" v-model="visual.materials" :clone="clone" item-key="id">
      <!-- Widget -->
      <template #item="{ element }">
        <visual-gui-flex class="material-item" :class="{ 'material-item-disabled': element.meta.disabled }" direction="column">
          <visual-gui-icon class="material-item-icon" :mode="element.meta.iconfont" :css="iconStyle" />
          <span>{{ element.meta.label }}</span>
        </visual-gui-flex>
      </template>
    </dragger>

    <!-- ******** FLUID ******** -->
    <dragger v-if="fluid" class="material-fluid" :group="{ name: 'groove', pull: 'clone', put: false }" :sort="false" animation="360" v-model="visual.materials" :clone="clone" item-key="id">
      <!-- Widget -->
      <template #item="{ element }">
        <visual-gui-flex class="material-item" :class="{ 'material-item-disabled': element.meta.disabled }" direction="column">
          <visual-gui-icon class="material-item-icon" :mode="element.meta.iconfont" :css="iconStyle" />
          <span>{{ element.meta.label }}</span>
        </visual-gui-flex>
      </template>
    </dragger>

    <!-- ******** GRID ******** -->
    <vue-drag-resize-motion-observer v-if="grid" :source="visual.materials" :span="2">
      <!-- Widget -->
      <template #item="{ item }">
        <visual-gui-flex class="material-item" :class="{ 'material-item-disabled': item.meta.disabled }" direction="column">
          <visual-gui-icon class="material-item-icon" :mode="item.meta.iconfont" :css="iconStyle" />
          <span>{{ item.meta.label }}</span>
        </visual-gui-flex>
      </template>
    </vue-drag-resize-motion-observer>
  </div>
</template>

<script>
export default {
  computed: {
    gather() {},
  },

  data() {
    return {
      iconStyle: {
        height: '36px',
        fontSize: `36px`,
        lineHeight: `1`,
        marginBottom: `8px`,
      },
    };
  },

  methods: {
    initOption(element) {
      // Preset Option
      element.option = element.option || {};

      // Preset CSS
      element.option.css = element.option.css || {};

      // Preset Properties
      element.option.css.flex = 1;
      element.option.css.padding = {};

      // Preset repeatIndex
      element.option.repeatIndex = 0;

      // Export Element
      return element;
    },

    clone(origin, { x, y, w, h } = {}) {
      // Set Component
      const component = _.cloneDeep(origin);

      // Merge Coordinate
      component.axes = { x, y, w, h };

      // Create ID of Runtime
      component.id = new Date().getTime() + '';

      // Active for UE
      component.active = false;

      // Init Resource
      component.source = this.resource;

      // Init Option
      return this.initOption(component);
    },
  },

  mounted() {
    this.visualUtils.round(360, () => {
      // No Materials
      if (this.visualUtils.empty(this.$materials)) {
        return;
      }

      // Get Materials
      const materials = Object.values(this.$materials).sort((before, after) => {
        return before.meta.index - after.meta.index;
      });

      // Update Materials
      this.$store.update('visual', { materials });

      // Endness
      return true;
    });
  },
};
</script>
