<style lang="less">
@import "../style/pack-skin.less";
</style>
<style lang="less" scoped>
.pack {
  &--header {
    height: 54px;
    line-height: 54px;
    padding: 0 16px;
    font-weight: bold;
  }

  :deep(.ant-collapse-borderless) {
    background-color: transparent;

    & > .ant-collapse-item {
      border-bottom: none;

      & > .ant-collapse-header {
        &:before {
          content: "";

          margin-right: 8px;
          padding: 0 2px;
          border-radius: 2px;
          background-color: #00a6c9;

          display: inline;
        }
      }
    }
  }
}
</style>

<template>
  <div class="pack pack-skin">
    <!--
    <i class="pack--anchor trigger">Test Trigger Move</i>
    -->

    <div class="pack--header" v-if="$slots.header">
      <slot name="header" />
    </div>

    <div class="pack--container">
      <a-collapse
        class="part"
        :bordered="false"
        v-model:active-key="namespace"
        :accordion="accordion"
        expand-icon-position="right"
      >
        <slot></slot>
      </a-collapse>
    </div>

    <div class="pack--footer" v-if="$slots.footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: [String],
      default() {
        return "";
      },
    },

    namespace: {
      type: [Array],
      default() {
        return [];
      },
    },

    accordion: {
      type: [Boolean],
      default() {
        return false;
      },
    },
  },
};
</script>
