// Use Map State
import { mapState } from 'vuex';

// Computed Stores
const storeMapping = mapState({
  // Visual Store
  visual: ({ visual }) => visual,
});

// Export
export default {
  computed: {
    ...storeMapping,
  },
};
