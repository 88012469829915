// Use Version
import packageInfo from '../../package.json';

// Use Noop
import noop from 'noop.js';

// Use Script Load
import loadScript from 'load-script';

// Use Css File Load
import loadCssFile from 'load-css-file';

// Use Query
import getUrlQuery from 'get-url-query';

// Use Agent
import getUserAgent from 'user-agents';

// Use Closest
import closestFind from 'closest-find';

// Use Deep
import deep4each from 'deep-for-each';

// Use Path Read
import pathReader from 'path-read';

// Use mm2px
import mmToPx from 'mm2px';

// Use px2mm
import pxToMm from 'px2mm';

// Use Modulo
import mathModulo from 'math.modulo';

// Use Post Bridge
import bridge from 'post-bridge';

// Use HTML2CANVAS
import htmlToCanvas from 'html2canvas';

// Use jsPDF
import jsPdf from 'jspdf';

// Use QRCode
import qrcode from 'qrcode';

// Use Print SDK
import { print, preview, pdf, copy } from '@skylla/sdk/api';

// Use Foreach
import { foreach, looper } from '@skylla/sdk/each';

// Use Print API
import { bindEventMessage, sendEventMessage } from '@skylla/sdk/message';

/**
 * Script
 * ========== ========== ==========
 */
export const loader = loadScript;

/**
 * CSS
 * ========== ========== ==========
 */
export const cssfile = loadCssFile;

/**
 * Query
 * ========== ========== ==========
 */
export const qs = getUrlQuery;

/**
 * Agent
 * ========== ========== ==========
 */
export const ua = getUserAgent;

/**
 * CloseST
 * ========== ========== ==========
 */
export const closest = closestFind;

/**
 * Deep
 * ========== ========== ==========
 */
export const deep = deep4each;

/**
 * Path
 * ========== ========== ==========
 */
export const pathRead = pathReader;

/**
 * MM Transfer PX
 * ========== ========== ==========
 */
export const mm2px = mmToPx;

/**
 * PX Transfer MM
 * ========== ========== ==========
 */
export const px2mm = pxToMm;

/**
 * Modulo
 * ========== ========== ==========
 */
export const modulo = mathModulo;

/**
 * HTML 2 CANVAS
 * ========== ========== ==========
 */
export const html2canvas = htmlToCanvas;

/**
 * JS PDF
 * ========== ========== ==========
 */
export const jsPDF = jsPdf;

/**
 * QR Code
 * ========== ========== ==========
 */
export const qrCode = qrcode;

/**
 * Foreach
 * ========== ========== ==========
 */
export const each = foreach;

/**
 * Looper
 * ========== ========== ==========
 */
export const loop = looper;

/**
 * Preview
 * ========== ========== ==========
 */
export const Preview = preview;

/**
 * Print
 * ========== ========== ==========
 */
export const Print = print;

/**
 * PDF
 * ========== ========== ==========
 */
export const Pdf = pdf;

/**
 * Copy
 * ========== ========== ==========
 */
export const Copy = copy;

/**
 * Post Bridge
 * ========== ========== ==========
 */
export const postBridge = bridge;

/**
 * Message Of
 * ========== ========== ==========
 */
export const sendMessage = sendEventMessage;
export const bindMessage = bindEventMessage;

/**
 * Capture
 * @param resolve { function }
 * @param reject { function }
 * ========== ========== ==========
 */
export async function capture(resolve = noop, reject = noop, process = 'unknow', crossover = {}) {
  try {
    await resolve(crossover);
  } catch (error) {
    await reject({ process, error });
  }
}

/**
 * Stylesheet
 * @param option { json }
 * ========== ========== ==========
 */
export async function stylesheet({ css }) {
  if (!css) {
    return false;
  }

  // Create Style Node
  const style = document.createElement('style');

  // Set Attribute Type
  style.setAttribute('type', 'text/css');

  // Insert CSS Content
  style.innerText = css;

  // Get Head
  const head = document.querySelector('head');

  // Append Node
  head.appendChild(style);

  // Exports
  return true;
}

/**
 * Loop
 * @param length { number } - default is 6
 * ========== ========== ==========
 */
export function random(length = 6) {
  return Math.round(new Date().getTime() * Math.random());
}

/**
 * Pixel
 * @param value { number | string }
 * ========== ========== ==========
 */
export function px(value, clone = true) {
  if ([undefined, null].includes(value)) {
    return value;
  }

  if ([String, Number].includes(value.constructor)) {
    return `${value}px`;
  }

  const cloner = clone ? {} : value;

  for (const i in value) {
    cloner[i] = `${value[i]}px`;
  }

  return cloner;
}

/**
 * Suffix
 * @param value { number }
 * ========== ========== ==========
 */
export function suffix(value = 0) {
  if ([undefined, null].includes(value)) {
    return value;
  }

  value = String(value);

  return value ? value.match(/^\-?\d+(?:\.\d{0,2})?/).shift() - 0 : value;
}

/**
 * Defer
 * @param value { any }
 * @param def { any }
 * ========== ========== ==========
 */
export function defer(value, def) {
  return (value = value === undefined ? def : value);
}

/**
 * Empty
 * @param target { object }
 * ========== ========== ==========
 */
export function empty(target = {}) {
  let sign = null;

  for (sign in target) {
    return false;
  }

  return true;
}

/**
 * Defer
 * @param time { timestamp }
 * @return { promise }
 * ========== ========== ==========
 */
export function wait(time = 0, count = 1, trigger) {
  // Use Promise
  return new Promise((resolve, reject, outer) => {
    // Outer
    outer = setTimeout(() => {
      if (trigger) {
        trigger(count);
      }

      resolve(count);
      clearTimeout(outer);

      // Repeat
      if (--count) {
        wait(time, count, trigger);
      }
    }, time);
  });
}

/**
 * Round
 * @param time { timestamp }
 * @param check { function }
 * ========== ========== ==========
 */
export function round(time = 360, check = () => {}) {
  return new Promise((resolve, reject) => {
    wait(time).then(async () => {
      (await check()) ? resolve() : round(time, check);
    });
  });
}

/**
 * Default
 * ========== ========== ==========
 */
export default packageInfo;
