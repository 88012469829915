<style lang="less" scoped>
.upload-image {
  .item {
    position: relative;
    float: left;
    margin: 0 6px 6px 0;
    width: 50px;
    height: 50px;
    border-radius: 1px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      &.active {
        padding: 2px;
        border: 2px solid @primary-color;
      }
    }
    .delete {
      display: none;
      position: absolute;
      z-index: 1;
      top: -7px;
      right: -7px;
      width: 14px;
      height: 14px;
      font-size: 14px;
    }
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    &:hover {
      cursor: pointer;
      .delete {
        display: block;
      }
    }
  }
}
</style>

<template>
  <div class="upload-image clearfix">
    <div class="item" v-for="(item, index) in list" :key="index">
      <img :src="item.filePath" :class="{ active: item.id === currentImgId }" @click="selectImg(item)" />
      <a-popconfirm placement="topRight" class="popConfirm" title="确认删除吗" ok-text="确认" cancel-text="取消" @click.stop @confirm.stop="confirmDelete(item, index)">
        <close-circle-two-tone class="delete" v-show="item.fileId !== currentImgId"></close-circle-two-tone>
      </a-popconfirm>
    </div>
  </div>
</template>

<script>
export default {
  name: 'image-list',

  props: {
    title: {
      type: [String],
      default() {
        return '';
      },
    },
    list: {
      type: Array,
      default: [],
    },
    loadImg: {
      type: [Function],
      default: () => {},
    },
  },

  computed: {
    currentImgId() {
      const backgroundActive = this.setting.backgroundActive;
      return (backgroundActive && backgroundActive.id) || '';
    },
  },
  data() {
    return {
      fileList: [],
    };
  },

  methods: {
    selectImg(item) {
      const bgImage = this.setting.backgroundActive && item.id === this.setting.backgroundActive.id ? '' : item;
      this.setting.backgroundCss.url = bgImage.filePath;
      this.setting.backgroundActive = bgImage;
    },

    async confirmDelete(item, index) {
      const result = await this.$store.dispatch('visual/OSS_DELETE', { id: item.id });

      if (result) {
        this.$nextTick(() => {
          this.$message.success(`删除成功`);
          this.loadImg();
        });
      }
    },
  },
};
</script>
