// Use Context
import { getPackages } from '../../__utils__/context';

// Exports
export default (argumenter = {}) => {
  // Query String
  argumenter.qs = argumenter.qs();

  // Export Packages
  return getPackages(require.context('./', true, /\.js$/), depend => depend(argumenter));
};
